import { Box, FormErrorMessage, Image, Container, FormControl, FormLabel, Input, useColorModeValue as mode, FormHelperText } from '@chakra-ui/react'
import * as React from 'react'
import { useContext } from 'react';
import { GlobalContext } from '../../Context';
import UploadService from '../../Service/UploadService';
import avatar from '../../../Media/images/avatar_default.png'
import placeholderImage from '../../../Media/images/image.png'


export const ImageForm = React.forwardRef((props, ref) => {
    const hiddenFileInput = React.useRef(null);
    const service = new UploadService()
    const { src, setValue, required, helpText, values, display, watch, placeholder, boxSize,getValues, label, errors, register, name, ...rest } = props
    const [source, setSource] = React.useState( watch(`${name}_full`) || src)
    const handleClick = event => {
        event.preventDefault()
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        var data = new FormData();
        data.append('file', fileUploaded);

        service.uploadImage(data, resp => {
            setSource({
                url: resp.data.url,
                alt: resp.data.nom,
                uuid: resp.data.uuid

            })
        },
            error => {
                console.log(error)
            })


        // onChange(fileUploaded);
    };
    // React.useEffect(() => {
    //     console.log("watch(`${name}_full`)?.url", watch(`${name}_full`), src)

    // }, [])
    React.useEffect(() => {
        setValue(name, source?.uuid)
        setValue(`${name}_full`, source)

    }, [source])

    return (
        <Container centerContent>
            <Box>
                <FormControl mt={5} isInvalid={errors[name]} display={display}>
                    <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                        {required ? `${label}*` : `${label}`}
                    </FormLabel>

                    <Image
                        // borderRadius="full"
                        boxSize={boxSize? boxSize: 'full'}
                        src={source?.url || placeholderImage}
                        alt={source?.alt && src?.alt}
                        onClick={handleClick}
                        borderColor={errors[name] ? "red" : "white"}

                    />
                    <input
                        accept="image/*"
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                    />
                    <Input
                        hidden
                        value={source?.uuid}
                        {...register(name)}
                        name={name}
                        {...rest}
                        focusBorderColor="brand.primary.100"
                        type="text"
                    />

                    <FormErrorMessage>
                        {errors[name]?.message}
                    </FormErrorMessage>
                    <FormHelperText >{helpText}</FormHelperText>
                </FormControl>
            </Box>
        </Container>
    )
})
ImageForm.displayName = 'ImageForm'