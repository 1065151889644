import {
    Avatar,
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Image,
    Link,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Divider
} from '@chakra-ui/react'
import * as React from 'react'
import { FiPlus } from 'react-icons/fi'
import Parser from 'html-react-parser';
import { ProjetCard } from './ProjetCard';

export const ListeProjets = ({ editable, user, organisation, projets, onOpen, setSelectedProjet }) => {

    const isMobile = useBreakpointValue({ base: true, md: false })
    return (
        <Box bg="bg-surface"
            pt={6}

        >
            <Container
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderTopWidth="4px"
                // borderTopWidth="4px"
                borderColor="brand.jaune.900"
                minW={{
                    base: '100%',
                    md: '100%',
                }}
                p={{ base: '5', md: '5' }}>
                <HStack pb={5}>
                    <Divider />
                    <Button flexShrink={0} variant="secondary" >
                        <Text fontSize="4xl" fontWeight="medium" whiteSpace="nowrap" color="brand.jaune.900">
                            {`Nos projets`.toUpperCase()}
                        </Text>
                    </Button>
                    <Divider />
                </HStack>

                <Stack spacing={{ base: '12', md: '16' }}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: '12', lg: '8' }}>
                        {projets.map((projet) => (
                            // <Link key={projet.id} _hover={{ textDecor: 'none' }} role="group">
                                <Stack spacing="8">
                                    <Box overflow="hidden">
                                        <Image
                                            src={projet?.banniere?.url}
                                            alt={projet?.banniere?.nom}
                                            width="full"
                                            height="15rem"
                                            objectFit="cover"
                                            transition="all 0.2s"
                                            _groupHover={{ transform: 'scale(1.05)' }}
                                        />
                                    </Box>
                                    <Stack spacing="3">
                                        <ProjetCard
                                            item={projet}
                                            onOpen={onOpen}
                                            setSelectedProjet={setSelectedProjet}
                                            editable={editable}
                                            organisation={organisation}
                                            user={user}
                                        />
                                        <Text fontSize="sm" fontWeight="semibold" color="jaune.900">
                                            {projet?.secteur?.nom}
                                        </Text>
                                        <Text color="muted">{Parser(`${projet?.presentation.slice(0, 300)}</p>`)}</Text>
                                    </Stack>
                                    <HStack>
                                        <Avatar src={organisation?.adminstrateur?.profil?.photo?.url} boxSize="10" />
                                        <Box fontSize="sm">
                                        <Text fontWeight="medium">Point Focal {organisation?.sigle} </Text>
                                            <Text fontWeight="medium">{organisation?.adminstrateur?.profil?`${organisation?.adminstrateur?.profil?.prenom} ${organisation?.adminstrateur?.profil?.nom}`: organisation?.adminstrateur?.mail} </Text>
                                            <Text color="muted">{user?.publishedAt}</Text>
                                        </Box>
                                    </HStack>
                                </Stack>
                           
                        ))}
                    </SimpleGrid>
                    {/* {isMobile && (
                        <Button bg="jaune.900" size="lg" color={"white"}>
                            Voir tout
                        </Button>
                    )} */}
                </Stack>
            </Container>
        </Box>
    )
}
