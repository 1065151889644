import {
    Avatar,
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Image,
    Link,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Divider,
    Icon,
    VStack
} from '@chakra-ui/react'
import * as React from 'react'
import { FiPlus } from 'react-icons/fi'
import { posts } from './data'
import Parser from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { UserInfo } from '../../Organisation/Projet/ProjetCard/UserInfo'
import { UilFacebook, UilGlobe, UilTwitter, UilCalling, UilMap, UilLinkedin, UilAt } from '@iconscout/react-unicons'
import { HiCalendar, HiCurrencyEuro } from 'react-icons/hi'
export const ListeAssociation = ({ title, organisations, disabled }) => {
    const isMobile = useBreakpointValue({ base: true, md: false })
    const navigate = useNavigate()
    return (
        <Box bg="bg-surface"
            pb={6}
            as="section"

        >
            <Container
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderTopWidth="4px"
                // borderTopWidth="4px"
                borderColor="brand.jaune.900"
                minW={{
                    base: '100%',
                    md: '80%',
                }}
                p={{ base: '5', md: '5' }}>
                <HStack pb={5}>
                    <Divider />
                    <Button flexShrink={0} variant="secondary" size={"md"} >
                        <Text fontSize="md" fontWeight="medium" whiteSpace="nowrap" color="brand.jaune.900">
                            {title}
                        </Text>
                    </Button>
                    <Divider />
                </HStack>

                <Stack spacing={{ base: '12', md: '16' }}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: '12', lg: '8' }}>
                        {organisations.map((organisation) => (

                            <Stack spacing="8">
                                <Box overflow="hidden">
                                    <Image
                                        src={organisation?.banniere?.url}
                                        alt={organisation?.banniere?.nom}
                                        width="full"
                                        height="15rem"
                                        objectFit="cover"
                                        transition="all 0.2s"
                                        _groupHover={{ transform: 'scale(1.05)' }}
                                    />
                                </Box>
                                <Stack spacing="3">
                                    <Link key={organisation.id} _hover={{ textDecor: 'none' }} role="group" href={`/organisation/${organisation?.id}`} isExternal>
                                        <Text fontSize="md" fontWeight="semibold" color="brand.primary.100">{organisation?.nom}</Text>
                                    </Link>
                                    <Text fontSize="sm" fontWeight="semibold" color="brand.jaune.900">
                                        {organisation?.pays?.nom}
                                    </Text>


                                    <Text color="muted" textAlign={"justify"} noOfLines={8}>{Parser(organisation?.description)}</Text>
                                </Stack>
                                <HStack>
                                    <Avatar src={organisation?.logo?.url} boxSize="16" />

                                    <VStack>
                                        {/* <HStack spacing="4" color="brand.primary.100"> */}
                                        <UserInfo elements={[

                                            {
                                                icon: UilFacebook,
                                                label: organisation?.facebook ? 'Facebook' : '',
                                                lien: organisation?.facebook ? organisation?.facebook : '',
                                            },
                                            {
                                                icon: UilTwitter,
                                                label: organisation?.twitter ? 'Twitter' : '',
                                                lien: organisation?.twitter ? organisation?.twitter : '',
                                            },
                                            {
                                                icon: UilGlobe,
                                                label: organisation?.site_web ? 'Site web' : '',
                                                lien: organisation?.site_web ? organisation?.site_web : '',
                                            },
                                            {
                                                icon: UilLinkedin,
                                                label: organisation?.linkdin ? 'Linkedin' : '',
                                                lien: organisation?.linkdin ? organisation?.linkdin : '',
                                            }
                                            , {
                                                icon: UilMap,
                                                label: organisation?.adresse || organisation?.province ? `${organisation?.adresse} ${organisation?.province} ${organisation?.pays?.nom}` : '',
                                                lien: '',
                                            },
                                            // {
                                            //     icon: UilCalling,
                                            //     label: organisation?.telephone ? `${organisation?.telephone}` : '',
                                            //     lien: '',
                                            // },
                                            // {
                                            //     icon: UilAt,
                                            //     label: organisation?.email ? organisation?.email : '',
                                            //     lien: '',
                                            // }
                                        ]} />

                                        {/* </HStack> */}
                                        {/* <Box fontSize="sm">
                                                <Text fontWeight="medium">Point Focal: {organisation?.adminstrateur?.profil?.prenom} {organisation?.adminstrateur?.profil?.nom}</Text>
                                                <Text color="muted">{organisation?.publishedAt}</Text>
                                            </Box> */}


                                    </VStack>
                                </HStack>

                            </Stack>

                        ))}
                    </SimpleGrid>
                    {/* {isMobile && (
                        <Button bg="jaune.900" size="lg" color={"white"}>
                            Voir tout
                        </Button>
                    )} */}
                </Stack>
                {!disabled && <Stack
                    spacing="4"
                    direction={{
                        base: 'column',
                        lg: 'row',
                    }}
                    justify="space-between"
                    py={5}
                >
                    <Box>
                        {/* <Link to="/registration">
                                <Button
                                    size={{ base: "sm", md: "md" }}
                                    w="full"
                                    leftIcon={<FiSend />}
                                    textTransform="uppercase"
                                    bg="jaune.900" color={"white"}>J'inscris mon organisation</Button>
                            </Link> */}
                    </Box>

                    <Box>
                        <Link to="/donateur">
                            <Button
                                size={{ base: "sm", md: "md" }}
                                w="full"
                                leftIcon={<FiPlus />}
                                textTransform="uppercase"
                                bg="bleu.900" color={"white"}>Plus d'opportunités de financement</Button>
                        </Link>
                    </Box>
                </Stack>}
            </Container>
        </Box>
    )
}
