import { Avatar, Box, Flex, useColorModeValue } from '@chakra-ui/react'

export const CardWithAvatar = (props) => {
  const { action, avatarProps, children, ...rest } = props
  return (
    <Flex
      position="relative"
      direction="column"
      align={{
        sm: 'center',
      }}
      maxW="2xl"
      mx="auto"
      bg={useColorModeValue('white', 'gray.700')}
      // shadow={{
      //   sm: 'base',
      // }}
      // rounded={{
      //   sm: 'lg',
      // }}
      px={{
        base: '6',
        md: '8',
      }}
      pb={{
        base: '6',
        md: '2',
      }}
      {...rest}
    >
      {/* <Avatar
        mt="-10"
        borderWidth="px"
        borderColor={useColorModeValue('white', 'gray.700')}
        size="2xl"
        {...avatarProps}
      /> */}

      {children}
      <Box
        position="relative"
        top="2"
        insetEnd={{
          base: '6',
          md: '5',
        }}
      >
        {action}
      </Box>
    </Flex>
  )
}
