import React from 'react'
import {
    Box,
    Stack,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
    useSteps,
} from '@chakra-ui/react'

function StepperComposant({
    steps,
    children,
    activeStep,
    setActiveStep,
    orientation
}) {

    return (
        <Stack direction={orientation === 'vertical' ? 'row' : 'column'} spacing={6} w="100%" h={"80%"}  p={5} as='section'>
            <Stepper index={activeStep} orientation={orientation} minH={orientation === 'vertical' ? '100%' : '40px'} gap='0' mt="2%"
            colorScheme='green'
            >

                {steps.map((step, index) => (
                    <Step key={index} >
                        <StepIndicator >
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>

                        <Box flexShrink='0' maxW="90%">
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>
                                <Box>
                                    {step.description}
                                </Box>
                            </StepDescription>
                        </Box>

                        <StepSeparator />
                    </Step>
                ))}
            </Stepper>
            <Box px={5} minW="80%" alignContent={'center'}>
                {children}
            </Box>
        </Stack>
    )
}

export default StepperComposant