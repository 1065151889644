import {
    Box,
    Button,
    Heading,
    Img,
    Stack,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { FiSend } from 'react-icons/fi'
  import { HiPlay } from 'react-icons/hi'
  import {Link} from "react-router-dom";
  import bg2 from './../../Media/images/bg2.png'
  export function Apropos() {
    return (
      <Box as="section" pb="10">
        <Box maxW={{ base: 'xl', md: 'full' }} mx="auto" px={{ base: '6', md: '8' }}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '3rem', lg: '2rem' }}
            mt="8"
            align={{ lg: 'center' }}
            justify="space-between"
          >
            <Box flex="1" maxW={{ lg: '55%' }}>
              <Heading
                as="h1"
                size={{base: "md", md: "lg"}}
                color={mode("brand.jaune.900", "brand.jaune.900")}
                mt="8"
                fontWeight="extrabold"
                letterSpacing="tight"
                textAlign='center'
              >
                Naibi SSO
              </Heading>
              <Text color={mode('white', 'white')} mt="4" fontSize={{base: "md", md: "xl"}} fontWeight="medium">
              {/* Cette plateforme digitale vise à faciliter la mise en relation des organisations de la société civile et des partenaires techniques et financiers et à recenser les opportunités de financements pour les organisations de la société civile au Sahel.  */}
              SSO simplifies the user experience by reducing the need for multiple logins, enhances security by centralizing authentication, and improves efficiency across an organization. By leveraging standard protocols and robust authentication mechanisms, SSO provides a secure and user-friendly way to manage access to multiple applications.

              </Text>
              {/* <Stack direction={{ base: 'column', md: 'row' }} spacing="4" mt="8">
                <Link to="/registration">
                <Button size="lg" minW="210px" colorScheme="teal"  leftIcon={<FiSend />}
                  textTransform="uppercase"
                  bg="brand.jaune.900" color={"white"}>J'inscris mon organisation
                </Button>
                </Link>
          
              </Stack> */}
            </Box>
            <Box pos="relative" w={{ base: 'full', lg: '40%' }} h={{ base: 'auto', lg: '400px' }}>
              <Img
                w="full"
                pos="relative"
                zIndex="1"
                h={{ lg: '100%' }}
                objectFit="cover"
                src={bg2}
                alt="Screening talent"
              />
              <Box
                pos="absolute"
                w="100%"
                h="100%"
                top="-6"
                left="-4"
                bgGradient='linear(to-tl,brand.jaune.900, brand.bleu.900)'
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    )
  }