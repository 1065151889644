import { Button, ButtonGroup, Flex, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, useColorModeValue, Box } from '@chakra-ui/react'
import React from 'react'
import StepperComposant from '../Task/StepperComposant'

function DrawerNS({
    onSubmit,
    title,
    buttons,
    isOpen,
    step,
    children,
    size,
    placement
}) {
    return (
        <Box minW={'80%'} mx={'20%'} pb={10} as='section'>
            <Drawer isOpen={isOpen} size={size} placement={placement} >
                <DrawerOverlay
                    bg='none'
                // backdropFilter='auto'
                // backdropInvert='10%'
                // backdropBlur='2px'
                />
                <form
                    onSubmit={onSubmit}
                >
                    <DrawerContent>
                        <DrawerHeader

                            color={"whiteAlpha.800"}
                            borderBottom={5}
                            borderStyle={'solid'}
                            borderColor={useColorModeValue('white.800', 'white.800')}
                            bg={'brand.primary.100'}
                        >{title}</DrawerHeader>
                        {/* <DrawerCloseButton /> */}
                        <DrawerBody>
                            {step?.steps?.length > 1 ?
                                <StepperComposant
                                    steps={step?.steps}
                                    activeStep={step?.activeStep}
                                    orientation={step?.orientation}
                                    setActiveStep={step?.setActiveStep}
                                >
                                    {children}
                                </StepperComposant> :
                                <Box px={5} minW="80%" alignContent={'center'} >{children}</Box>
                            }


                        </DrawerBody>
                        <DrawerFooter
                            bg={useColorModeValue('gray.800', 'gray.800')}
                            color={"whiteAlpha.800"}
                            mt="2%"
                            borderTop={5}
                            borderStyle={'solid'}
                            borderColor={'brand.primary.100'}
                        >
                            <ButtonGroup mt="2%" w="100%"

                            >
                                <Flex w="100%" justifyContent="space-between">
                                    {buttons?.map(button => {
                                        return <Flex>
                                            <Button
                                                color={"whiteAlpha.900"}
                                                variant="solid"
                                                w="7rem"
                                                mr="5%"
                                                {...button?.propriety}
                                                bgGradient="linear(to-r,brand.secondary, brand.primary.100)"
                                            >

                                                {button?.label}
                                            </Button>
                                        </Flex>
                                    })}
                                </Flex>
                            </ButtonGroup>
                        </DrawerFooter>
                    </DrawerContent>
                </form>
            </Drawer>
        </Box>
    )
}

export default DrawerNS