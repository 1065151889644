import React, { useState, useEffect, useContext, useRef } from 'react';

import * as yup from "yup";
import { Button, useDisclosure, useSteps, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../Context';
import MagicFormNS from '../../Task/MagicFormNS';
import { MdEdit, MdSave } from 'react-icons/md';

const ProjetForm = ({
    isOpen,
    onToggle,
    onClose,
    organisation,
    selectedProjet,
    setSelectedProjet,
    onGetAll,
    administrator

}) => {
    const { service, dataUser, token, logout } = useContext(GlobalContext)
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 2,
    })
    const toast = useToast()
    const navigate=useNavigate()
    const [secteurs, setSecteurs] = useState([]);
    const [profil, setProfil] = useState({});

    const onSubmit = async (data) => {

        const { banniere_id_full,banniere,organisation: organisation2, secteur, url,id, ...rest } = data
        !data?.id? service.create(token, "projet", { ...rest, organsiation_id: organisation?.id, date_debut: new Date(data?.date_debut).toISOString(), date_fin: new Date(data?.date_fin).toISOString() },
            (resp) => {
                onGetAll()
                setSelectedProjet({})
                funcs?.current?.reset({})
                onClose()
                toast({
                    title: 'Succes.',
                    description: "Le projet a ete cree avec succes",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

            }, err => {
                if (err.response?.status === 405) {
                    toast({
                        title: 'Probleme est survenu.',
                        description: "Le compte mail est deja utilise",
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }

            }):
            service.update(token, "projet", data?.id, { ...rest, organsiation_id: organisation?.id, date_debut: new Date(data?.date_debut).toISOString(), date_fin: new Date(data?.date_fin).toISOString() },
            (resp) => {
                onGetAll()
                setSelectedProjet({})
                funcs?.current?.reset({})
                onClose()
                toast({
                    title: 'Succes.',
                    description: "Le projet a ete cree avec succes",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

            }, err => {
                if (err.response?.status === 405) {
                    toast({
                        title: 'Probleme est survenu.',
                        description: "Le compte mail est deja utilise",
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }

            })





    }
    const schemaInit = {
        nom: yup.string().required("Champ est obligatoire"),
        secteur_id: yup.string().required("Champ  est obligatoire"),
        presentation: yup.string().required("Champ est obligatoire"),
        date_debut: yup.string().required("Champ est obligatoire"),
        banniere_id: yup.string().required("Champ  est obligatoire"),


    }
    const steps = [
        {
            title: 'Details',
            description: 'Informations du projet',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    size: "lg",
                    onClick: () => {
                        funcs?.current?.reset({})
                        setSelectedProjet({})
                        onClose()
                    }
                }
            },
            {
                label: selectedProjet?.id ? 'Modifier': "Sauvegarder",
                propriety: {
                    isDisabled: false ,
                    type: "submit",
                    size: "xl",
                    px: '10',
                    icon: selectedProjet?.id ? <MdEdit /> : <MdSave />,
                    display: organisation?.administrateur_id ? "block": "none"
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }
    // const [item, setItem] = useState(selectedProjet);


    const champsInit = (watch) => [
     

        [

            {
                name: "nom",
                label: "Intitulé du projet",
                type: "text",
                placeholder: "Entrer l'intitulé du projet",

                requiredText: 'true',
                isDisabledText: organisation?.administrateur_id? false: true,
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "sigle",
                label: "Sigle",
                type: "text",
                placeholder: "Entrer le sigle",
                requiredText: 'false',
                isDisabledText:  organisation?.administrateur_id? false: true,
                displayText: "true",
                // prenom: yup.string().required("The status is required"),
                helpText: ""


            },
        ],
       
        [
            {
                name: "secteur_id",
                label: "Secteur d'activite",
                type: "select",
                placeholder: "---selectionner---",
                requiredText: 'true',
                isDisabledText:  organisation?.administrateur_id? false: true,
                displayText: "true",
                helpText: "",

                liste: secteurs?.map(item => {
                    return { key: item?.id, value: item?.nom }

                })
            },
        ],
        [
            {
                name: "budget",
                label: "Budget du projet",
                type: "number",
                placeholder: "Entrer le nom",
                requiredText: 'false',
                isDisabledText:  organisation?.administrateur_id? false: true,
                displayText: "true",
                helpText: "",
            },
            {
                name: "device",
                label: "Device",
                type: "select",
                placeholder: "---selectionner---",
                requiredText: 'false',
                isDisabledText:  organisation?.administrateur_id? false: true,
                displayText: "true",
                helpText: "",
                liste: [{ key: 'XFA', value: 'XFA' }, { key: 'XOF', value: 'XOF' }, { key: 'DOLLAR', value: 'Dollar' }, { key: 'EURO', value: 'Euro' }]
            },
        ],
        [
            {
                name: "date_debut",
                label: "Date debut du projet",
                type: "date",
                placeholder: "Entrer le nom",
                requiredText: 'true',
                isDisabledText:  organisation?.administrateur_id? false: true,
                displayText: "true",
                helpText: "",
            },
            {
                name: "date_fin",
                label: "Date fin du projet",
                type: "date",
                placeholder: "---selectionner---",
                requiredText: 'false',
                isDisabledText:  organisation?.administrateur_id? false: true,
                displayText: "true",
                helpText: "",

            },
        ],
           [
            {
                name: "banniere_id",
                label: "Photo d'illustration du projet'",
                type: "image",
                placeholder: "Entrer le nom",

                requiredText: 'true',
                isDisabledText:  organisation?.administrateur_id? false: true,
                displayText: "true",
                helpText: "",
                src: selectedProjet?.banniere


                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
        ],
        [
            {
                name: "presentation",
                label: "Description du projet",
                type: "richtextarea",
                placeholder: "Entrer le nom",
                requiredText: 'true',
                isDisabledText:  organisation?.administrateur_id? false: true,
                displayText: "true",
                helpText: "",
                defaultValue: selectedProjet?.presentation



            },
        ],

    ]
    useEffect(() => {
        funcs?.current?.reset(profil)
    }, [profil]);
    // useEffect(() => {
    //     onGet()
    // }, [projet_id]);
    useEffect(() => {
        service.getAll(token, "secteur", (resp) => {
            setSecteurs(resp?.data?.data)
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
        funcs?.current?.reset({...selectedProjet,
            secteur_id: selectedProjet?.secteur?.id,
            date_debut: selectedProjet?.date_debut?.split('T')[0],
            date_fin: selectedProjet?.date_fin?.split('T')[0],
            budget:  selectedProjet?.budget || 0,
            device: selectedProjet?.device || ""
        })
    }, [ selectedProjet]);
    return (<section>

        {<MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={selectedProjet}
            title="Projet de l'OSC"
            view='drawer'
            size='xl'
        />}
    </section>);
}

export default ProjetForm;