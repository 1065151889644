import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Task from "./Pages/Task";
import Administration from "./Pages/Administration";
import { GlobalContext } from "./Pages/Context";
import RedFlagDocument from "./Pages/RedFlag";
import Login from "./Pages/Login";
import Header from "./Pages/Header";
import { Box } from "@chakra-ui/react";
import Footer from "./Pages/Footer";
import Parametrage from "./Pages/Parametrage";
import Organisation from "./Pages/Organisation";
import { Donateur } from "./Pages/donateur";
import { Association } from "./Pages/association";
import Registration from "./Pages/Registration";
import Financement from "./Pages/Organisation/Financement";


const RouteApplication = () => {
    const { token, retrieve, dataUser } = useContext(GlobalContext)
    // useEffect(() => {
    //     retrieve()
    //     console.log("In Route", token)
    // }, [token])



    return (
        <>
            {/* <BrowserRouter> */}
            <Routes>

                <Route exact path="/" element={
                    <>
                        <Header />
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Home />
                        </Box>
                        <Footer />
                    </>

                } />


                <Route exact path="/donateur" element={
                    <>
                        <Header />
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Donateur />
                        </Box>
                        <Footer />
                    </>

                } />
                <Route exact path="/association" element={
                    <>
                        <Header />
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Association />
                        </Box>
                        <Footer />
                    </>

                } />
                {dataUser?.user?.role_code === "RO-001" && <Route exact path="/administration" element={<>
                    <Header />
                    <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                        <Administration />
                    </Box>
                    <Footer />
                </>} />}
                {<Route exact path="/organisation/:id" element={
                    <>
                        <Header />
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Organisation />
                        </Box>
                        <Footer />
                    </>
                } />}
                {<Route exact path="/financement/:id" element={
                    <>
                        <Header />
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Financement />
                        </Box>
                        <Footer />
                    </>
                } />}
                <Route exact path="/parametrage" element={<>
                    <Header />
                    <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                        <Parametrage />
                    </Box>
                    <Footer />
                </>} />

                <Route exact path="/administration" element={
                    <>
                        <Header />
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Administration />
                        </Box>
                        <Footer />
                    </>
                } />
                <Route exact path="/login" element={token ?
                    <>
                        <Header />
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Home />
                        </Box>
                        <Footer />
                    </> :
                    <Login />
                } />
                <Route exact path="/:token" element={
                    <>
                        {/* <Header /> */}
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Registration />
                        </Box>
                        {/* <Footer /> */}
                    </>

                } />
                <Route exact path="/*" element={
                    <>
                        <Header />
                        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }} pb={5}>
                            <Home />
                        </Box>
                        <Footer />
                    </>
                } />

            </Routes>
            {/* </BrowserRouter> */}
        </>
    );
}

export default RouteApplication;