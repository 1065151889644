import React from 'react'
import { InputForm } from './InputForm';
import { TextAreaForm } from './TextAreaForm';
import { SelectForm } from './Select';
import { RadioForm } from './RadioForm';
import { CheckBoxForm } from './CheckBoxForm';
import { NumberForm } from './NumberForm';
import { MultiSelectForm } from './MultiSelectForm';
import { RichTextAreaForm } from './RichTextAreaForm';
import { ImageForm } from './ImageForm';

export const Field = (
    props
) => {
    const { type } = props
    switch (type) {
        case "text":
        case 'email':
        case 'password':
        case 'tel':
        case 'date':
        case 'time':

            return <InputForm {...props} />;
        case 'textarea':
            return <TextAreaForm {...props} />
        case "select":
            return <SelectForm {...props} />;
        case "multiselect":
            return <MultiSelectForm {...props} />;
        case 'radio':
            return <RadioForm {...props} />;
        case "checkbox":
            return <CheckBoxForm {...props} />;
        case 'richtextarea':
            return <RichTextAreaForm {...props} />
        case 'number':
            return <NumberForm {...props} />
        case 'image':
            return <ImageForm {...props} />
        default:
            return ""
    }
}
Field.displayName = 'Field'