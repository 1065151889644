import { Button, Container, Divider, HStack, Stack, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Pays from './Pays';
import Secteur from './Secteur';
import Donateur from './Donateur';
import { FaTools } from 'react-icons/fa';
import Organisation from '../Organisation';
import OrganisationListe from './Organisation';
import Financement from './Financement';

const Parametrage = ({

}) => {
    return (
        <>
            <Container
                pt={{
                    base: '8',
                    lg: '12',
                }}
                pb={{
                    base: '5',
                    lg: '5',
                }}
                minW={{
                    base: '100%',
                    md: '80%',
                }}
            >
                <HStack>
                    <Divider />
                    <Button variant="secondary" leftIcon={<FaTools />}  color="white" bg={'brand.primary.100'} fontSize={'lg'}>
                        Gestion des paramètres
                    </Button>
                    <Divider />
                </HStack>
                <Stack spacing="24" direction={{ base: "row", "md": "column" }} shouldWrapChildren
                    shadow='lg' borderBottomWidth={4} borderBottomColor={"brand.primary.100"}
                >
                    <Tabs size={"lg"}
                        variant="line"

                    >
                        <TabList color={'brand.primary.100'} fontSize={'lg'}>
                            <Tab >Pays</Tab>
                            <Tab>Secteurs d'activités</Tab>
                            <Tab>Donateurs</Tab>
                            <Tab>Financements</Tab>
                            <Tab>Organisations</Tab>
                        </TabList>
                        <TabIndicator />
                        <TabPanels >
                            <TabPanel>
                                <Pays

                                />


                            </TabPanel>
                            <TabPanel>
                                <Secteur />
                            </TabPanel>
                            <TabPanel>
                                <Donateur />
                            </TabPanel>
                            <TabPanel>
                                <Financement />
                            </TabPanel>
                            <TabPanel>
                                <OrganisationListe />
                            </TabPanel>
                            

                        </TabPanels>
                        {/* <TabIndicator /> */}
                    </Tabs>


                </Stack>

            </Container>
        </>);
}

export default Parametrage;