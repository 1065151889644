import { HStack, Icon, Link, Stack, Text, Wrap, WrapItem, useColorModeValue } from '@chakra-ui/react'
import { HiCalendar, HiLink, HiLocationMarker } from 'react-icons/hi'

export const UserInfo = (props) => {
  const { elements, ...stackProps } = props
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <Wrap
      direction={{
        base: 'column',
        sm: 'row',
      }}
      spacing={{
        base: '1',
        sm: '6',
      }}
      mt="4"
      fontSize="sm"
      fontWeight="medium"
      color={useColorModeValue('brand.primary.100', 'brand.primary.100')}
      {...stackProps}
      // justifyContent="space-between"
      px={"10%"}
    >
      {elements?.map(element => {
        return (
          <WrapItem>
            {element?.lien ?
              <Link href={element?.lien} isExternal>
                <HStack >
                  <Icon as={element?.icon} boxSize={6} />
                  <Text>{element?.label}</Text>
                </HStack>
              </Link>
              :
              element?.label && <HStack >
                <Icon as={element?.icon} boxSize={6} />
                <Text>{element?.label}</Text>
              </HStack>
            }
          </WrapItem>
        )

      })}


    </Wrap>
  )
}
