import { Box, Flex, Heading, Image, Link, Stack, useColorModeValue } from '@chakra-ui/react'

export const Banner = ({
    nom,
    sigle,
    item,
}) => (
  <Box position="relative">
    <Image
      src={item?.banniere?.url}
      alt={item?.banniere?.nom}
      objectFit="cover"
      width="100%"
      height="md"
    />
    <Box position="absolute" boxSize="full" inset="0" zIndex="1">
      <Flex
        direction="column-reverse"
        height="full"
        maxW="7xl"
        mx="auto"
        px={{
          base: '4',
          md: '8',
          lg: '12',
        }}
        py={{
          base: '6',
          md: '8',
          lg: '12',
        }}
      >
        <Box
          bg={useColorModeValue('gray.800', 'gray.800')}
          alignSelf={{
            md: 'start',
          }}
          p={{
            base: '5',
            md: '8',
          }}
          minW={{
            md: 'lg',
          }}
        >
          <Stack spacing="5">
            <Stack spacing="1">
              {/* <Heading size="lg" color={useColorModeValue("brand.primary.100", 'brand.secondary')}>
               {sigle}
              </Heading> */}
              <Heading size="md" color={useColorModeValue('white', 'white')}>
               {nom}
              </Heading>
            </Stack>
          </Stack>
        </Box>
      </Flex>
    </Box>
  </Box>
)