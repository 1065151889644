import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps, useToast, HStack, Divider, Stack, Text, useBreakpointValue, useColorModeValue, Container, IconButton, Image, Avatar, Link } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave, MdSkipNext, MdSkipPrevious } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { FaFacebook, FaLinkedin, FaTwitter, FaWordpress } from 'react-icons/fa';
import { Banner } from './Banner';
import Parser from 'html-react-parser';
import { FiPlus, FiSearch } from 'react-icons/fi';
import avatar from '../../Media/images/image.png'
import { Presentation } from './Presentation';
import ProjetForm from './Projet';
import { ListeProjets } from './Projet/liste';
import { BannerFinancement } from './BannerFinacement';
import { PresentationFinancement } from './Presentation/PresentationFinance';
const Financement = ({

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const { isOpen: isOpenProjet, onClose: onCloseProjet, onOpen: onOpenProjet, onToggle: onToggleProjet } = useDisclosure()
    const navigate = useNavigate()
    const { id } = useParams()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const isMobile = useBreakpointValue({ base: true, md: true, lg: false })
    const [projets, setProjets] = useState([]);
    const [selectedProjet, setSelectedProjet] = useState({});
    const [item, setItem] = useState({});
    const onGet = () => {
        // console.log("dataUser", dataUser, item)
        service.get(token, `financement/${id}`, "", (resp) => {
            // alert(JSON.stringify(resp?.data?.data))
            setItem(resp?.data)


        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                if (err?.response?.status === 404) {
                    onOpen()
                }
            }
        )
    }
    useEffect(() => {

    }, [item]);
    useEffect(() => {
        onGet()
    }, []);
    return (
        <Box w="auto" shadow='lg'>

            <Container
                pb={{
                    base: '12',
                    lg: '24',
                }}
                minW={{
                    base: '100%',
                    md: '80%',
                }}
                shadow={'md'}
            >
                <Stack
                    spacing="4"
                    direction="column"
                    justify="space-between"
                >
                    <BannerFinancement
                        nom={item?.nom}
                        sigle={item.sigle}
                        item={item}
                    />
                    <PresentationFinancement item={item} onOpen={onOpen} admin={dataUser?.user} />

                    <Box
                        boxShadow={useColorModeValue('sm', 'sm-dark')}
                        borderTopWidth="6px"
                        // borderTopWidth="4px"
                        borderColor="brand.bleu.900"
                        minW={{
                            base: '100%',
                            md: '80%',
                        }}
                        p={{ base: '5', md: '5' }}
                    >
                        {<Stack
                            spacing="4"
                            direction={{base:"column", md:"row"}}
                            justify="space-between"
                        >
                            <Box>
                                <Text
                                    textTransform="uppercase"
                                    fontSize={{base:"md", md:"lg"}} fontWeight="medium">
                                    Présentation de l'opportunité de financement
                                </Text>

                            </Box>
                            <Box>
                              {item?.lien &&  <Button flexShrink={0} variant="secondary"  as={Link}
                                w={{base:"full", md:"md"}}
                                >
                                  <Link key={item.id} _hover={{ textDecor: 'none' }} role="group" href={item?.lien} isExternal>
                                     <Text fontSize={{base:"md", md:"md"}} fontWeight="medium" whiteSpace="nowrap" color="brand.jaune.900">
                                        Soumission
                                    </Text>
                                    </Link>
                                </Button>}
                            </Box>
                        </Stack>}
                    </Box>


                </Stack>
                <Box>
                    <Stack
                        spacing="4"
                        direction={{ base: "column", md: "column" }}
                        justify="space-between"
                    >
                        <Box width={{ base: "full", md: "full" }}>
                            <Image
                                src={item?.banniere?.url}
                                alt={item?.banniere?.nom}
                                objectFit="cover"
                                width="100%"
                                height="md"
                                display={{ base: 'block', md: 'block' }}
                            />
                        </Box>
                        <Box width={{ base: "full", md: "full" }}

                        >
                            <Text>{item?.presentation && Parser(item?.presentation)}</Text>

                        </Box>
                        <HStack>
                            <Avatar src={item?.donateur?.logo?.url} boxSize="10" />
                            <Box fontSize="sm">
                                <Text fontWeight="medium">{item?.donateur?.nom}</Text>
                                <Text color="muted">{`Date de publication: ${item?.date_publication?.split('T')[0]}`}</Text>

                            </Box>
                        </HStack>
                    </Stack>

                </Box>

            </Container>

        </Box>);
}

export default Financement;

