import {
    Avatar,
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Image,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Divider,
    Link
} from '@chakra-ui/react'
import * as React from 'react'
import { posts } from './data'
// import {  } from "react-router-dom";
import { FiPlus, FiSend } from 'react-icons/fi';
import Parser from 'html-react-parser';
import { UserInfo } from '../../Organisation/Projet/ProjetCard/UserInfo';
import { HiCalendar, HiCurrencyEuro } from 'react-icons/hi';

export const ListeADonateur = ({ title, financements }) => {
    const isMobile = useBreakpointValue({ base: true, md: false })
    return (
        <Box bg="bg-surface"
            pb={6}
            as="section"
        >
            <Container
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderTopWidth="4px"
                // borderTopWidth="4px"
                borderColor="brand.bleu.900"
                minW={{
                    base: '100%',
                    md: '80%',
                }}
                p={{ base: '5', md: '5' }}>
                <HStack pb={5}>
                    <Divider size='md' />
                    <Button flexShrink={0} variant="secondary" size={{base: "sm", md:"md" }}>
                        <Text fontSize={{base: "sm", md:"md" }} fontWeight="medium" color={"brand.bleu.900"}>
                            {title}
                        </Text>
                    </Button>
                    <Divider />
                </HStack>

                <Stack spacing={{ base: '12', md: '16' }}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: '12', lg: '8' }}>
                        {financements?.map((financement) => (

                            <Stack spacing="8">
                                <Box overflow="hidden">
                                    <Image
                                        src={financement.banniere?.url}
                                        alt={financement.banniere?.nom}
                                        width="full"
                                        height="15rem"
                                        objectFit="cover"
                                        transition="all 0.2s"
                                        _groupHover={{ transform: 'scale(1.05)' }}
                                    />
                                </Box>
                                <Stack spacing="3">
                                    <Link key={financement.id} _hover={{ textDecor: 'none' }} role="group" href={`/financement/${financement?.id}`} isExternal>
                                        <Text fontSize="lg" fontWeight="semibold" color={"brand.bleu.900"}>{financement.nom}</Text>
                                    </Link>
                                    <Text fontSize="md" fontWeight="semibold" >
                                        {financement?.secteur?.nom}
                                    </Text>
                                    <UserInfo elements={[
                                        {
                                            icon: HiCalendar,
                                            label: `Date clôture: ${financement?.date_cloture.split('T')[0]}`
                                        },
                                        {
                                            icon: HiCurrencyEuro,
                                            label: `Budget: ${financement?.budget} ${financement?.device} `
                                        }

                                    ]} />

                                    {/* <Text fontSize="md" fontWeight="semibold">{`Date de clôture: ${financement.date_cloture.split('T')[0]}`}</Text> */}
                                    <Text color="muted" textAlign={"justify"} noOfLines={8}>{Parser(financement?.presentation)}</Text>
                                </Stack>
                                <HStack>
                                    <Avatar src={financement?.donateur?.logo?.url} boxSize="10" />
                                    <Box fontSize="sm">
                                        <Text fontWeight="medium">{financement?.donateur?.nom}</Text>
                                        <Text color="muted">{`Date de publication: ${financement.date_publication.split('T')[0]}`}</Text>

                                    </Box>
                                </HStack>
                            </Stack>

                        ))}
                    </SimpleGrid>
                    {/* {true && (
                        <Button bg="bleu.900" size="lg" color={"white"}>
                        <Link to="donateur">
                        
                            Voir toutes les opportunités existantes
                        
                        </Link>
                        </Button>
                    )} */}
                </Stack>
                <Stack
                    spacing="4"
                    direction={{
                        base: 'column',
                        lg: 'row',
                    }}
                    justify="space-between"
                    py={5}
                >
                    <Box>
                        {/* <Link to="/registration">
                                <Button
                                    size={{ base: "sm", md: "md" }}
                                    w="full"
                                    leftIcon={<FiSend />}
                                    textTransform="uppercase"
                                    bg="jaune.900" color={"white"}>J'inscris mon organisation</Button>
                            </Link> */}
                    </Box>

                    <Box>
                        <Link to="/donateur">
                            <Button
                                size={{ base: "sm", md: "md" }}
                                w="full"
                                leftIcon={<FiPlus />}
                                textTransform="uppercase"
                                bg="bleu.900" color={"white"}>Plus d'opportunités de financement</Button>
                        </Link>
                    </Box>
                </Stack>
            </Container>
        </Box>
    )
}
