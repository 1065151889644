import { Box } from '@chakra-ui/react'

export const CardContent = (props) => (
  <Box
    textAlign={{
      sm: 'center',
    }}
    pt="2"
    {...props}
  />
)
