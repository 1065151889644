import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
const OrganisationListe = ({

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const navigate = useNavigate()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const [organisations, setOrganisations] = useState([]);
    const [item, setItem] = useState({});
    const onGet = () => {
        service.getAll(token, "organisation", (resp) => {
            setOrganisations(resp?.data?.data)
            // alert(JSON.stringify(resp?.data?.data))

        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
    }
    const columns = [
        {
            title: 'Organisation',
            field: 'nom',
            // render: rowData => <><ReactCountryFlag countryCode={rowData?.code}/> {rowData?.nom}</>
        },
        {
            title: 'Abreviation',
            field: 'sigle',
        }
    ]
    useEffect(() => {

    }, [organisations]);
    useEffect(() => {
        onGet()
    }, []);
    return (
        <Box w="full">
            <Datatable
                icon={<BsListCheck />}
                title={"Liste des organisations"}
                data={organisations}
                onGet={onGet}
                columns={columns}
                setData={setOrganisations}
                setItem={setItem}
                onToggle={onToggle}
                canAdd={true}
                canEdit={true}
            />
            {/* <SimpleTableNS
                title="Liste des pays membres"
                data={{
                    header: ["Code", "Nom", "Action"],
                    body: pays
                }}
                maxW="600px"
                onOpen={onOpen}
            /> */}
            <FormOrganisateur
                isOpen={isOpen}
                onClose={onClose}
                onGet={onGet}
                item={item}


            />

        </Box>);
}

export default OrganisationListe;

const FormOrganisateur = ({
    isOpen,
    onToggle,
    onClose,
    onGet,
    item

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    let navigate = useNavigate();
    const { service, token, logout } = useContext(GlobalContext)
    const onSubmit = async (data) => {


        const { id, tableData, url, banniere_id_full, logo_id_full, ...rest } = data

        !id ? service.create(token, "organisation", rest, (resp) => {
            onGet()
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        ) :
            service.update(token, "organisation", id, rest, (resp) => {
                onGet()
            },
                err => {
                    if (err?.response?.status === 401) {
                        logout()
                        navigate('/login')
                    }
                    console.error(err?.response?.status === 401)
                }
            )






    }
    const schemaInit = {
        nom: yup.string().required("Champ est obligatoire"),
        sigle: yup.string().required("Champ  est obligatoire").uppercase(),
        presentation: yup.string().required("Champ  est obligatoire"),
        email: yup.string().required("Champ  est obligatoire"),
        logo_id: yup.string().required("Champ  est obligatoire"),
        banniere_id: yup.string().required("Champ  est obligatoire"),
    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    size: "md",
                    icon: <MdClose />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: "Supprimer",
                propriety: {
                    isDisabled: false,
                    display: !item?.id && 'none',
                    w: "full",
                    px: '10',
                    icon: <MdDelete />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: item?.id ? "Modifier" : "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    size: "md",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSave />,
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }


    const champsInit = (watch) => [

        [

            {
                name: "nom",
                label: "Organisation",
                type: "text",
                placeholder: "Entrer le nom du organisation",

                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "sigle",
                label: "Abreviation",
                type: "text",
                placeholder: "Entrer le sigle",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""


            },
        ],
        [

            {
                name: "site_web",
                label: "Site web",
                type: "text",
                placeholder: "Entrer le site du organisation",

                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "email",
                label: "Email",
                type: "email",
                placeholder: "Entrer le mail du organisation",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""


            },
        ],
        [

            {
                name: "logo_id",
                label: "logo",
                type: "image",
                placeholder: "Entrer le site du organisation",

                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                boxSize:'200px',
                src: item?.logo

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "banniere_id",
                label: "Photo illustration",
                type: "image",
                placeholder: "Entrer le mail du organisation",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                boxSize:'200px',
                src: item?.banniere


            },
        ],
        [

            {
                name: "presentation",
                label: "Presentation",
                type: "richtextarea",
                placeholder: "Entrer le site du organisation",

                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                defaultValue: item?.presentation


            }
        ]]
    useEffect(() => {
        funcs.current.reset(item)
    }, [item]);
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Formulaire "
            size='xl'
            view='drawer'
        />
    </>);
}
