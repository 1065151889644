import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
const Financement = ({
    Donateur

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const navigate = useNavigate()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const [financements, setFinancements] = useState([]);

    const [item, setItem] = useState({});
    const onGet = () => {
        service.getAll(token, "financement", (resp) => {
            setFinancements(resp?.data?.data)
            // alert(JSON.stringify(resp?.data?.data))

        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
    }
    const columns = [
        {
            title: 'Financement',
            field: 'nom',
            // render: rowData => <><ReactCountryFlag countryCode={rowData?.code}/> {rowData?.nom}</>
        },
        {
            title: 'Donateur',
            field: 'donateur_id',
             render: rowData =><>{rowData?.donateur?.sigle}</>
        },
        {
            title: 'Budget',
            field: 'budget',
             render: rowData =><>{`${rowData?.budget} ${rowData?.device} `}</>
        }
    ]
    useEffect(() => {

    }, [financements]);
    useEffect(() => {
        onGet()
    }, []);
    return (
        <Box w="full" >
            <Datatable
                icon={<BsListCheck />}
                title={"Liste des finacements"}
                data={financements}
                onGet={onGet}
                columns={columns}
                setData={setFinancements}
                setItem={setItem}
                onToggle={onToggle}
                canAdd={true}
                canEdit={true}
            />
            {/* <SimpleTableNS
                title="Liste des pays membres"
                data={{
                    header: ["Code", "Nom", "Action"],
                    body: pays
                }}
                maxW="600px"
                onOpen={onOpen}
            /> */}
            <FormFinancement
                isOpen={isOpen}
                onClose={onClose}
                onGet={onGet}
                item={item}


            />

        </Box>);
}

export default Financement;

const FormFinancement = ({
    isOpen,
    onToggle,
    onClose,
    onGet,
    item

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    let navigate = useNavigate();
    const [secteurs, setSecteurs] = useState([]);
    const [donateurs, setDonateurs] = useState([]);
    const { service, token, logout } = useContext(GlobalContext)
    const onSubmit = async (data) => {


        const { id, tableData, url, banniere_id_full, logo_id_full,banniere, secteur, donateur, ...rest } = data

        !id ? service.create(token, "financement", {
            ...rest,
            date_cloture: new Date(rest?.date_cloture).toISOString(),
            date_publication: new Date(rest?.date_publication).toISOString(),
        }, (resp) => {
            onGet()
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        ) :
            service.update(token, "financement", id, {
                ...rest,
                date_cloture: new Date(rest?.date_cloture).toISOString(),
                date_publication: new Date(rest?.date_publication).toISOString(),
            }, (resp) => {
                onGet()
            },
                err => {
                    if (err?.response?.status === 401) {
                        logout()
                        navigate('/login')
                    }
                    console.error(err?.response?.status === 401)
                }
            )






    }
    const schemaInit = {
        nom: yup.string().required("Champ est obligatoire"),
        sigle: yup.string().required("Champ  est obligatoire").uppercase(),
        presentation: yup.string().required("Champ  est obligatoire"),
        secteur_id: yup.string().required("Champ  est obligatoire"),
        date_publication: yup.string().required("Champ est obligatoire"),
        date_cloture: yup.string().required("Champ est obligatoire"),
        banniere_id: yup.string().required("Champ  est obligatoire"),
    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    size: "md",
                    icon: <MdClose />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: "Supprimer",
                propriety: {
                    isDisabled: false,
                    display: !item?.id && 'none',
                    w: "full",
                    px: '10',
                    icon: <MdDelete />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: item?.id ? "Modifier" : "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    size: "md",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSave />,
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }


    const champsInit = (watch) => [
        [
            {
                name: "donateur_id",
                label: "Bailleur",
                type: "select",
                placeholder: "---selectionner---",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",

                liste: donateurs?.map(item => {
                    return { key: item?.id, value: item?.nom }

                })
            },],
        [

            {
                name: "lien",
                label: "Site web",
                type: "text",
                placeholder: "Entrer le lien du financement",

                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
        ],
        [

            {
                name: "nom",
                label: "Intitulé du financement",
                type: "text",
                placeholder: "Entrer l'intitulé du financement",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "sigle",
                label: "Abreviation",
                type: "text",
                placeholder: "Entrer le sigle",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""


            },
        ],
        [
            {
                name: "secteur_id",
                label: "Secteur d'activite",
                type: "select",
                placeholder: "---selectionner---",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",

                liste: secteurs?.map(item => {
                    return { key: item?.id, value: item?.nom }

                })
            },
        ],
        [
            {
                name: "budget",
                label: "Budget du financemanet",
                type: "number",
                placeholder: "Entrer le budget du financemanet",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
            },
            {
                name: "device",
                label: "Device",
                type: "select",
                placeholder: "---selectionner---",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                liste: [{ key: 'XFA', value: 'XFA' }, { key: 'XOF', value: 'XOF' }, { key: 'DOLLAR', value: 'Dollar' }, { key: 'EURO', value: 'Euro' }]
            },
        ],
        [
            {
                name: "date_publication",
                label: "Date publication",
                type: "date",
                placeholder: "Entrer le nom",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
            },
            {
                name: "date_cloture",
                label: "Date clôture",
                type: "date",
                placeholder: "---selectionner---",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",

            },
        ],

        [

            {
                name: "banniere_id",
                label: "Photo illustration",
                type: "image",
                placeholder: "Entrer le mail du financement",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                boxSize: '200px',
                src: item?.banniere


            },
        ],
        [
            {
                name: "presentation",
                label: "Description du financement",
                type: "richtextarea",
                placeholder: "Entrer la description du financement ",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                defaultValue: item?.presentation



            },
        ],
    ]
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    useEffect(() => {
        service.getAll(token, "secteur", (resp) => {
            setSecteurs(resp?.data?.data)
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
        service.getAll(token, "donateur", (resp) => {
            setDonateurs(resp?.data?.data)
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
        funcs?.current?.reset({
            ...item,
            secteur_id: item?.secteur?.id,
            donateur_id: item?.donateur?.id,
            date_publication: item?.date_publication?.split('T')[0],
            date_cloture: item?.date_cloture?.split('T')[0],
        })
    }, [item]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Formulaire "
            size='xl'
            view='drawer'
        />
    </>);
}
