import { Box, Button, Heading, Text, useColorModeValue, Image } from '@chakra-ui/react'
import { HiCalendar, HiCurrencyDollar, HiCurrencyEuro, HiPencilAlt } from 'react-icons/hi'
import { CardContent } from './CardContent'
import { CardWithAvatar } from './CardWithAvatar'
import { UserInfo } from './UserInfo'

export const ProjetCard = ({
  item,
  onOpen,
  admin,
  setSelectedProjet,
  editable,
  user,
  organisation
}) => (
  <Box as="section" position="relative">
    <Box position="relative" />
    <CardWithAvatar
      maxW="3xl"
      avatarProps={{
        src: item?.logo?.url,
        name: item?.logo?.nom
      }}
      action={
       editable  &&   <Button size="md" color='brand.primary.100'  leftIcon={<HiPencilAlt />}
          onClick={() => {
            console.log("item", item)
            setSelectedProjet(item);
            onOpen()


          }}
        >
          {user?.id === organisation?.adminstrateur?.id ?  "Editer" : "Details projet" }
        </Button>
      }
    >
      <CardContent>
        <Heading size="lg" fontWeight="extrabold" letterSpacing="tight">
          {item?.sigle}
        </Heading>
        <Text color={useColorModeValue('brand.jaune.900', 'brand.rouge.900')} fontWeight="extrabold" letterSpacing="tight">
          {item?.nom}
        </Text>
        <UserInfo elements={[
          {
            icon: HiCalendar,
            label: `Fin projet: ${item?.date_debut.split('T')[0]}`
          },

        ]} />
       {item?.budget!==0 && item?.device && <UserInfo elements={[

          {
            icon: HiCurrencyEuro,
            label: `Budget: ${item?.budget} ${item?.device} `
          }
        ]} />}
        {/* <UserInfo location="Memphis, USA" website="esther.com" memberSince="Joined Sept. 2019" /> */}
      </CardContent>
    </CardWithAvatar>
  </Box>
)
