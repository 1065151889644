import {  FormControl, FormErrorMessage, FormHelperText, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import React from 'react'

export const RadioForm = React.forwardRef((props, ref) => {
    const { name, label,helpText, liste, defaultValue, placeholder, required, errors, control, Controller, direction, display} = props

    return (
        <>
            <FormControl mt={5}  isInvalid={errors[name]} display={display}>
                <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                    {required ? `${label}*` : `${label}`}
                </FormLabel>

                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref } }) => (
                        <RadioGroup
                            placeholder={placeholder}
                            onChange={onChange}
                            value={value}

                        >
                            <Stack spacing={[1, 5]} direction={direction}>
                                {liste.map((item, index) => {
                                    return (
                                        <Radio id={index} value={item.key} colorScheme='green'>{item.value}</Radio>
                                    )
                                })
                                }
                            </Stack>
                        </RadioGroup>
                    )}
                />
              
                <FormErrorMessage>
                    {errors[name]?.message}
                </FormErrorMessage>
                <FormHelperText>{helpText}</FormHelperText>
            </FormControl>
        </>
    )
})
RadioForm.displayName = 'RadioForm'