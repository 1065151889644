import { Box, Button, Heading, Text, useColorModeValue, Image } from '@chakra-ui/react'
import { HiCalendar, HiCurrencyEuro, HiPencilAlt } from 'react-icons/hi'
import { CardContent } from './CardContent'
import { CardWithAvatar } from './CardWithAvatar'
// import { UserInfo } from './UserInfo'
import { UilFacebook, UilGlobe, UilTwitter, UilCalling, UilMap, UilLinkedin, UilAt } from '@iconscout/react-unicons'
import { UserInfo } from '../Projet/ProjetCard/UserInfo'

export const PresentationFinancement = ({
    item,
    onOpen,
    admin
}) => (
    <Box as="section" position="relative" >
        <Box position="relative" />
        <CardWithAvatar
            maxW="3xl"
            avatarProps={{
                src: item?.donateur?.logo?.url,
                name: item?.donateur?.logo?.nom
            }}
            action={
                admin?.role?.code === "RO-001" &&
                <Button size="md" color={'brand.primary.100'} leftIcon={<HiPencilAlt />}
                    onClick={onOpen}

                >
                    Editer
                </Button>
            }
        >
            <CardContent>
                <Heading size="md" fontWeight="extrabold" letterSpacing="tight" textAlign="center">
                    {item?.sigle}
                </Heading>
                <Text color={useColorModeValue('gray.600', 'gray.400')}>
                    {item?.nom}
                </Text>
                {/* <UserInfo location="Memphis, USA" website="esther.com" memberSince="Joined Sept. 2019" />
        <UserInfo location="Memphis, USA" website="esther.com" memberSince="Joined Sept. 2019" />  */}
                <UserInfo elements={[
                    {
                        icon: HiCalendar,
                        label: `Date clôture: ${item?.date_cloture?.split('T')[0]}`
                    },
                    {
                        icon: HiCurrencyEuro,
                        label: `Budget: ${item?.budget} ${item?.device} `
                    }

                ]} />
            </CardContent>
        </CardWithAvatar>
    </Box>
)
