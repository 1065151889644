import { useState, useEffect, useContext, useMemo } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  InputRightElement,
  FormErrorMessage,
  PinInput,
  PinInputField,
  HStack,
  Spacer,
  Stack,
  Center,
  Text,
  Link,
  Divider,
  IconButton
} from '@chakra-ui/react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast } from '@chakra-ui/react'
import { GlobalContext } from '../Context';
import { LogoSAVert } from '../Header/LogoSA';
import { useNavigate } from 'react-router-dom';
import { UilEllipsisH } from '@iconscout/react-unicons'
const LoginForm = ({
  props
}) => {
  const { login, retrieve } = useContext(GlobalContext)
  const [isLogin, setIsLogin] = useState(false)
  let navigate = useNavigate();
  const schema = yup.object().shape({
    mail: yup.string().email("L'adresse mail n'est pas valide").required("Le mail est obligatoire"),
    motpasse: yup.string().min(8, "Minimum 8 caractere").required("Le mot de passe est obligatoire"),
    // confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
  });

  const onSubmit = async (data) => {

    const resp = login(data)

    if (resp) {
      setIsLogin(!isLogin)
      navigate('/')

    }


  }
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(schema)
  });
  useEffect(() => {
    if (isLogin) {
      retrieve()
    }

  }, [isLogin])

  return (

    <Stack spacing="2" {...props} w={{ base: "full", md: 'lg' }} as="section"
      // borderColor="brand.primary.100"
      // shadow='lg'
      // px={5}

    >

      <Stack spacing="6" fontSize={{md: "2xl", base: "md"}}>
        <form
        >

          <FormControl mt="2%" isInvalid={errors?.mail} size={{ base: "md", md: 'lg' }} fontSize={{ base: "md", md: 'lg' }}>
            <FormLabel htmlFor="email" fontWeight={'normal'}>
              Email*
            </FormLabel>
            <Input id="email" type="email"  size={{ base: "md", md: 'lg' }} {...register("mail")} name="mail" placeholder="Entrer votre email" />
            <FormErrorMessage>
              {errors?.mail && errors?.mail?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl mt="2%" isInvalid={errors?.motpasse} size={{ base: "md", md: 'lg' }} fontSize={{ base: "md", md: 'lg' }}>
            <FormLabel htmlFor="password" fontWeight={'normal'}>
              Mot de passe*
            </FormLabel>
            <Input id="motpasse" type="password" {...register("motpasse")} name="motpasse" placeholder="Entrer votre mot de passe" />
            <FormErrorMessage>
              {errors?.motpasse && errors?.motpasse?.message}
            </FormErrorMessage>
          </FormControl>


          <Box textAlign={"right"} pt={5}>
            <Link href='reset'> <Text fontSize={{ base: "xs", md: 'md' }} color={'brand.primary.100'}>Mot de passe oublié?</Text></Link>
          </Box>
          <ButtonGroup mt="5%" w="100%">
            <Flex w="100%" justifyContent="space-between">
              <Spacer />
              {<Button
                w="full"
                color={"whiteAlpha.900"}
                bgGradient="linear(to-l,brand.secondary, brand.primary.100)"
                variant="solid"
                onClick={handleSubmit(onSubmit)}
                size={"lg"}
              >
                Connexion
              </Button>

              }
              <Box></Box>
            </Flex>
          </ButtonGroup>

        </form>
      </Stack>

      <HStack>
        <Divider />
        <IconButton variant="tertiary" icon={<UilEllipsisH />} aria-label="Add" />
        <Divider />
      </HStack>
      <Box textAlign={"center"} pb={5}>
    
           <Button as={Link} href='/registration'
            w="full"
            bgColor={"whiteAlpha.900"}
            color="brand.primary.100"
            variant="solid"
          ><Text fontSize="md" color={'brand.primary.100'}>Création d'un nouveau compte</Text></Button>
      </Box>
    </Stack>
  )
}

export default LoginForm