import { FormControl, FormErrorMessage, FormLabel, Box, Textarea, FormHelperText } from '@chakra-ui/react'
import React from 'react'
import EditorField from './EditorForm'

export const RichTextAreaForm = React.forwardRef((props, ref) => {
    const { name, label, helpText, type, register, Controller, control, watch, defaultValue, required, errors, display } = props
    const [value, setValue] = React.useState('')
    // React.useEffect(() => {
    //     console.log("value====>", value)
    // }, [value])
    // React.useEffect(() => {
    //     setValue(watch(name))
    // }, [watch(name)])
    return (
        <>
            <FormControl mt={5} isInvalid={errors[name]} display={display}>
                <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                    {required ? `${label}*` : `${label}`}
                </FormLabel>
                <Controller
                    control={control}
                    name={name}
                    // defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref } }) => (
                        <Box
                            minH={"400px"}
                            maxW="100%"
                            boxShadow='base'
                            px={2}
                        >
                            <EditorField
                                defaultValue={defaultValue}
                                getText={onChange}
                            />
                        </Box>
                    )}
                />

                <FormErrorMessage>
                    {errors[name]?.message}
                </FormErrorMessage>
                <FormHelperText >{helpText}</FormHelperText>
            </FormControl>

        </>
    )
})
RichTextAreaForm.displayName = 'RichTextAreaForm'