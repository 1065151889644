import {
    Box,
    Button,
    Heading,
    Img,
    Stack,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { FiSend } from 'react-icons/fi'
  import { HiPlay } from 'react-icons/hi'
  import {Link} from "react-router-dom";
  
  export function BannerAssociation() {
    return (
      <Box as="section" bg={mode('gray.50', 'gray.800')} pb="24">
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '3rem', lg: '2rem' }}
            mt="8"
            align={{ lg: 'center' }}
            justify="space-between"
          >
            <Box flex="1" maxW={{ lg: '520px' }}>
              <Heading
                as="h1"
                size="lg"
                color={mode("brand.jaune.900", "brand.jaune.900")}
                mt="8"
                fontWeight="extrabold"
                letterSpacing="tight"
              >
                Organisation de la Société Civile
              </Heading>
              <Text color={mode('gray.600', 'gray.400')} mt="4" fontSize="lg" fontWeight="medium">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </Text>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4" mt="8">
                <Link to="/registration">
                <Button size="lg" minW="210px" colorScheme="teal"  leftIcon={<FiSend />}
                  textTransform="uppercase"
                  bg="brand.jaune.900" color={"white"}>J'inscris mon organisation
                </Button>
                </Link>
          
              </Stack>
            </Box>
            <Box pos="relative" w={{ base: 'full', lg: '560px' }} h={{ base: 'auto', lg: '560px' }}>
              <Img
                w="full"
                pos="relative"
                zIndex="1"
                h={{ lg: '100%' }}
                objectFit="cover"
                src="https://images.unsplash.com/photo-1459183885421-5cc683b8dbba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                alt="Screening talent"
              />
              <Box
                pos="absolute"
                w="100%"
                h="100%"
                top="-4"
                left="-4"
                bgGradient='linear(to-tl,brand.jaune.900, brand.primary.100)'
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    )
  }