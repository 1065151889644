import {
    Avatar,
    AvatarGroup,
    Box,
    Center,
    DarkMode,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import LogoSA from '../../Media/images/logo.png'
import RegistrationForm from './RegistrationForm'
import { Routes, Route, useParams } from 'react-router-dom';
import RegistrationFormUser from './LoginFormUser';

const Registration = () => {
    const { token } = useParams()
    // React.useEffect(() => {
    //     console.log("token", token, mode)
    // }, [token])

    return <Flex width="100%"
        minH={{
            base: 'auto',
            md: '100vh',
        }}

    >
        <Box
            flex="1"
            display={{
                base: 'none',
                md: 'block',
            }}
            bgGradient="linear(to-r,brand.secondary, brand.primary.100)"
            maxW={"50%"}
            px="auto"

        >
            <DarkMode>
                <Flex
                    direction="column"
                    px={{
                        base: '4',
                        md: '8',
                    }}
                    height="full"
                    color="on-accent"
                    align={"center"}
                >
                    <Flex align="center" h="24">
                        {/* <LogoSA/> */}
                    </Flex>
                    <Flex flex="1" align="center" color="white">
                        <Stack spacing="8">
                            <Stack spacing="6">
                                <Heading
                                    size={useBreakpointValue({
                                        md: 'lg',
                                        xl: 'xl',
                                    })}
                                    color="white"
                                >
                                    Naibi SSO
                                </Heading>
                                <Text fontSize="lg" maxW="md" fontWeight="medium">
                                SSO simplifies the user experience by reducing the need for multiple logins, enhances security by centralizing authentication, and improves efficiency across an organization. By leveraging standard protocols and robust authentication mechanisms, SSO provides a secure and user-friendly way to manage access to multiple applications.
                                </Text>
                            </Stack>
                            <HStack spacing="4">
                                <AvatarGroup
                                    size="md"
                                    max={useBreakpointValue({
                                        base: 2,
                                        lg: 5,
                                    })}
                                    borderColor="on-accent"
                                >
                                    <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                                    <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                                    <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                                    <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
                                    <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
                                </AvatarGroup>
                                <Text fontWeight="medium">Joindre 100+ Utilisateur</Text>
                            </HStack>
                        </Stack>
                    </Flex>
                    <Flex align="center" h="24">
                        <Text color="white" fontSize="sm">
                            © 2024 Naibi . All rights reserved.
                        </Text>
                    </Flex>
                </Flex>
            </DarkMode>
        </Box>
        <Center flex="1"  >
            <RegistrationForm
                token={token}
            // pr={20}
            // px={{
            //     base: '4',
            //     md: '8',
            // }}
            // py={{
            //     base: '12',
            //     md: '48',
            // }}
            // minW="2xl"
            />
        </Center>
    </Flex>
    // </Flex>
}
export default Registration