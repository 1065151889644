import { Box, Button, Heading, Text, useColorModeValue, Image } from '@chakra-ui/react'
import { HiPencilAlt } from 'react-icons/hi'
import { CardContent } from './CardContent'
import { CardWithAvatar } from './CardWithAvatar'
// import { UserInfo } from './UserInfo'
import { UilFacebook, UilGlobe, UilTwitter, UilCalling, UilMap, UilLinkedin, UilAt } from '@iconscout/react-unicons'
import { UserInfo } from '../Projet/ProjetCard/UserInfo'

export const Presentation = ({
  item,
  onOpen,
  admin
}) => (
  <Box as="section" position="relative" >
    <Box position="relative" />
    <CardWithAvatar
      maxW="3xl"
      avatarProps={{
        src: item?.logo?.url,
        name: item?.logo?.nom
      }}
      action={
        (admin === item?.adminstrateur?.id) && 
        <Button size="md"  color={'brand.primary.100'} leftIcon={<HiPencilAlt />}
          onClick={onOpen}

        >
          Editer 
        </Button>
      }
    >
      <CardContent>
        <Heading size="md" fontWeight="extrabold" letterSpacing="tight" textAlign="center">
          {item?.sigle}
        </Heading>
        <Text color={useColorModeValue('gray.600', 'gray.400')}>
          {item?.nom}
        </Text>
        {/* <UserInfo location="Memphis, USA" website="esther.com" memberSince="Joined Sept. 2019" />
        <UserInfo location="Memphis, USA" website="esther.com" memberSince="Joined Sept. 2019" />  */}
        <UserInfo elements={[

          {
            icon: UilFacebook,
            label: item?.facebook ? 'Facebook' : '',
            lien: item?.facebook ? item?.facebook : '',
          },
          {
            icon: UilTwitter,
            label: item?.twitter ? 'Twitter' : '',
            lien: item?.twitter ? item?.twitter : '',
          },
          {
            icon: UilGlobe,
            label: item?.site_web ? 'Site web' : '',
            lien: item?.site_web ? item?.site_web : '',
          },
          {
            icon: UilLinkedin,
            label: item?.linkdin ? 'Linkedin' : '',
            lien: item?.linkdin ? item?.linkdin : '',
          }
        ]} />
        <UserInfo elements={[

          {
            icon: UilMap,
            label: item?.adresse || item?.province ? `${item?.adresse} ${item?.province} ${item?.pays?.nom}` : '',
            lien: '',
          },
          {
            icon: UilCalling,
            label: item?.telephone ? `${item?.telephone}` : '',
            lien: '',
          },
          {
            icon: UilAt,
            label: item?.email ? item?.email : '',
            lien: '',
          }
        ]} />
      </CardContent>
    </CardWithAvatar>
  </Box>
)
