import React from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function EditorField({
    defaultValue,
    getText

}) {
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const cancelRef = React.useRef()
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        getText(draftToHtml(convertToRaw(editorState.getCurrentContent())))

    }
    // const editorState=React.useMemo(() => {
    //     const blocksFromHtml = htmlToDraft(defaultValue);
    //         const { contentBlocks, entityMap } = blocksFromHtml;
    //         const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    //         return EditorState.createWithContent(contentState)
    // }, [defaultValue])
    React.useEffect(() => {
        if (defaultValue) {
            const blocksFromHtml = htmlToDraft(defaultValue);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }

    }, [defaultValue])

    return (
        <>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                localization={{
                    locale: 'en',
                }}
                toolbar={{
                    fontFamily: {
                        options: ['Poppins', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    colorPicker: {

                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        colors: ["#336646", '#008080', "#B80000", '#9C6615', '#5F0F40', '#FF9800'],
                    },
                }}
            />

        </>
    )
}

export default EditorField