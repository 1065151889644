import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps, useToast, HStack, Divider, Stack, Text, useBreakpointValue, useColorModeValue, Container, IconButton, Image } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave, MdSkipNext, MdSkipPrevious } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { FaFacebook, FaLinkedin, FaTwitter, FaWordpress } from 'react-icons/fa';
import { Banner } from './Banner';
import Parser from 'html-react-parser';
import { FiPlus, FiSearch } from 'react-icons/fi';
import avatar from '../../Media/images/image.png'
import { Presentation } from './Presentation';
import ProjetForm from './Projet';
import { ListeProjets } from './Projet/liste';
const Organisation = ({

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const { isOpen: isOpenProjet, onClose: onCloseProjet, onOpen: onOpenProjet, onToggle: onToggleProjet } = useDisclosure()
    const navigate = useNavigate()
    const { id } = useParams()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const isMobile = useBreakpointValue({ base: true, md: true, lg: false })
    const [projets, setProjets] = useState([]);
    const [selectedProjet, setSelectedProjet] = useState({});
    const [item, setItem] = useState({});
    const onGet = () => {
        // console.log("dataUser", dataUser, item)
        service.get(token, `organisation/${id}`, "", (resp) => {
            // alert(JSON.stringify(resp?.data?.data))
            setItem(resp?.data)
            // alert(JSON.stringify(resp?.data?.data))
            service.get(token, `organisation/${resp?.data?.id}/projets`, "", (response) => {
                // alert(JSON.stringify(resp?.data?.data))
                console.log("response?.data", response?.data)
                setProjets(response?.data?.data)
                // alert(JSON.stringify(resp?.data?.data))

            },
                err => {
                    if (err?.response?.status === 401) {
                        logout()
                        navigate('/login')
                    }
                    if (err?.response?.status === 404) {
                        onOpen()
                    }
                }
            )

        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                if (err?.response?.status === 404) {
                    onOpen()
                }
            }
        )
    }
    const columns = [
        {
            title: 'Nom',
            field: 'libelle',

        },
        {
            title: 'Code',
            field: 'code',
        }
    ]
    useEffect(() => {

    }, [item, selectedProjet]);
    useEffect(() => {
        if (id === "neworganisation") {
            onToggle()
        }
        else {

            onGet()
        }

    }, []);
    return (
        <Box w="auto" shadow='lg'>

            <Container
                pb={{
                    base: '12',
                    lg: '24',
                }}
                minW={{
                    base: '100%',
                    md: '80%',
                }}
                shadow={'md'}
            >
                <Stack
                    spacing="4"
                    direction="column"
                    justify="space-between"
                >
                    <Banner
                        nom={item?.nom}
                        sigle={item.sigle}
                        item={item}
                    />
                    <Presentation item={item} onOpen={onOpen} admin={dataUser?.user?.id} />

                    <Box
                        boxShadow={useColorModeValue('sm', 'sm-dark')}
                        borderTopWidth="6px"
                        // borderTopWidth="4px"
                        borderColor="brand.bleu.900"
                        minW={{
                            base: '100%',
                            md: '80%',
                        }}
                        p={{ base: '5', md: '5' }}
                    >
                        {<Stack
                            spacing="4"
                            direction="row"
                            justify="space-between"
                        >
                            <Box>
                                <Text
                                    textTransform="uppercase"
                                    fontSize="2xl" fontWeight="medium">
                                    Presentation de l'organisation
                                </Text>

                            </Box>
                            <Box>

                            </Box>
                        </Stack>}
                    </Box>


                </Stack>
                <Box>
                    <Stack
                        spacing="4"
                        direction={{ base: "column", md: "column", lg: 'row' }}
                        justify="space-between"
                    >
                        {isMobile && <Box width={{ base: "full", md: "full", lg: "40%" }}>
                            <Image
                                src={item?.banniere?.url}
                                alt={item?.banniere?.nom}
                                objectFit="cover"
                                width="100%"
                                height="md"
                                display={{ base: 'block', md: 'block', lg: 'none' }}
                            />
                        </Box>}
                        <Box width={{ base: "full", md: "full", lg: "60%" }}

                        >
                            <Text>{item?.description && Parser(item?.description)}</Text>

                        </Box>
                        {!isMobile && <Box width={{ base: "full", md: "full", lg: "40%" }}>
                            <Image
                                src={item?.banniere?.url}
                                alt={item?.banniere?.nom}
                                objectFit="cover"
                                width="100%"
                                height="md"
                                display={{ base: 'none', md: 'none', lg: 'block' }}
                            />
                        </Box>}
                    </Stack>

                    <Box
                        boxShadow={useColorModeValue('sm', 'sm-dark')}
                        borderTopWidth="6px"
                        // borderTopWidth="4px"
                        borderColor="brand.bleu.900"
                        minW={{
                            base: '100%',
                            md: '80%',
                        }}
                        mt={5}
                        p={{ base: '5', md: '5' }}
                    >
                        {dataUser?.user?.id === item?.adminstrateur?.id && <HStack>
                            <Divider />
                            <Button variant="secondary"
                                bg='brand.jaune.900'
                                color={'white'}
                                leftIcon={<FiPlus />}
                                w='md'
                                onClick={onOpenProjet}
                            >
                                Ajouter un projet
                            </Button>
                            <Divider />
                        </HStack>}
                        <ListeProjets
                            projets={projets}
                            organisation={item}
                            user={dataUser?.user}
                            onOpen={onOpenProjet}
                            selectedProjet={selectedProjet}
                            setSelectedProjet={setSelectedProjet}
                            editable={dataUser?.user?.id === item?.adminstrateur?.id}

                        />
                    </Box>

                </Box>

            </Container>
            <FormOrganisation
                isOpen={isOpen}
                onClose={onClose}
                onGet={onGet}
                item={{ administrateur_id: dataUser?.user?.id, ...item }}
                setItem={setItem}

            />
            <ProjetForm
                isOpen={isOpenProjet}
                onOpen={onOpenProjet}
                onClose={onCloseProjet}
                organisation={{ administrateur_id: dataUser?.user?.id, ...item }}
                selectedProjet={selectedProjet}
                setSelectedProjet={setSelectedProjet}
                onGetAll={onGet}
            />

        </Box>);
}

export default Organisation;

export const FormOrganisation = ({
    isOpen,
    onClose,
    item,
    setItem

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 4,
    })
    let navigate = useNavigate();
    const toast = useToast()
    const { service, token, logout, dataUser } = useContext(GlobalContext)
    const [data, setData] = useState(item);
    const [pays, setPays] = useState([]);
    const onSubmit1 = async (formData) => {
        const { tableData, ...rest } = formData
        setData({ ...data, ...formData, })
        setActiveStep(activeStep + 1)
    }
    const onSubmit0 = async (formData) => {
        const { tableData, adminstrateur, ...rest } = formData

        service.create(token, "utilisateurs/organisation", {
            mail: formData?.mail,
            motpasse: formData?.motpasse
        },
            (resp) => {
                setData({ ...data, administrateur_id: resp?.data?.id })
                toast({
                    title: 'Succes.',
                    description: "Le compte mail a ete cree avec succes",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

                setActiveStep(activeStep + 1)

            }, err => {
                if (err.response?.status === 405) {
                    toast({
                        title: 'Probleme est survenu.',
                        description: "Le compte mail est deja utilise",
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }

            })

    }
    const onSubmit = async (data) => {


        const { id, adminstrateur, tableData, logo_id_full, banniere_id_full, url, logo, banniere, pays, email2, telephone2, ...rest } = data

        !id ? service.create(token, "organisation", { administrateur_id: item?.administrateur_id, ...rest }, (resp) => {
            setItem(resp?.data)
            onClose()
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        ) :
            service.update(token, "organisation", id, rest, (resp) => {
                setItem(resp?.data)
                onClose()
            },
                err => {
                    if (err?.response?.status === 401) {
                        logout()
                        navigate('/login')
                    }
                    console.error(err?.response?.status === 401)
                }
            )






    }
    const schemaInit2 = {
        telephone: yup.string().required("Le champ telephone de l'organisation est obligatoire"),
        email: yup.string().email("Le mail n'est pas valide").required("Le champ email de l'organisation est obligatoire"),
        pays_id: yup.string().required("Le pays de l'organisation est obligatoire"),
        province: yup.string().required("La province de l'organisation est obligatoire"),
        adresse: yup.string().required("L'adresse de l'organisation est obligatoire"),

    }
    const schemaInit1 = {
        nom: yup.string().required("Le champ nom de l'organisation est obligatoire"),
        description: yup.string().required("The status is required"),
        // diplome: yup.array().min(1, "Choisir au moins un").required("The diplome is required").of(yup.string()),
        sigle: yup.string().required("Le sigle de l'organisation est obligatoire"),

    }
    const schemaInit3 = {

    }
    const schemaInit0 = {

        mail: yup.string().email("Le mail n'est pas valide").required("Le champ email de l'organisation est obligatoire"),
        motpasse: yup.string().required("The password is required").min(8, "Minimum 8 characters"),
        motpasse2: yup
            .string()
            .required('Confirmer le mot de psse.')
            .oneOf([yup.ref('motpasse')], 'Les mot de passes ne sont pas identiques .')
    }
    const steps = [
        {
            title: 'Aministrateur',
            description: 'Creation compte',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    size: "md",
                    icon: <MdClose />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        setActiveStep(0)
                        onClose()
                    }
                }
            },

            {
                label: item?.id ? "Creer" : "Creer",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    size: "md",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSkipNext />,
                }
            }]
        },
        {
            title: 'Presentation',
            description: 'Information generale',
            buttons: !item?.administrateur_id ?
                [{
                    label: "Retour",
                    propriety: {
                        isDisabled: false,
                        size: "md",
                        icon: <MdSkipPrevious />,
                        onClick: () => {
                            // funcs?.current?.reset({})
                            setActiveStep(activeStep - 1)
                        }
                    }
                }
                    ,
                {
                    label: item?.id ? "Suivant" : "Suivant",
                    propriety: {
                        isDisabled: false,
                        type: "submit",
                        size: "md",
                        px: '10',
                        icon: item?.id ? <MdEdit /> : <MdSkipNext />,
                    }
                }] :
                [{
                    label: "Quitter",
                    propriety: {
                        isDisabled: false,
                        size: "md",
                        icon: <MdClose />,
                        onClick: () => {
                            funcs?.current?.reset({})
                            onClose()
                        }
                    }
                },

                {
                    label: item?.id ? "Suivant" : "Suivant",
                    propriety: {
                        isDisabled: false,
                        type: "submit",
                        size: "md",
                        px: '10',
                        icon: item?.id ? <MdEdit /> : <MdSkipNext />,
                    }
                }]

        },
        {
            title: 'Coordonnees',
            description: 'Adresse',
            buttons: [{
                label: "Retour",
                propriety: {
                    isDisabled: false,
                    size: "md",
                    icon: <MdSkipPrevious />,
                    onClick: () => {
                        setActiveStep(activeStep - 1)
                    }
                }
            },

            {
                label: item?.id ? "Suivant" : "Suivant",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    size: "md",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSkipNext />,
                }
            }]
        },
        {
            title: 'Illustration',
            description: 'Banniere et logo',
            buttons: [{
                label: "Retour",
                propriety: {
                    isDisabled: false,
                    size: "md",
                    icon: <MdSkipPrevious />,
                    onClick: () => {
                        setActiveStep(activeStep - 1)
                    }
                }
            },

            {
                label: item?.id ? "Suivant" : "Suivant",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    size: "md",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSkipNext />,
                }
            }]
        },
        {
            title: 'Reseaux sociaux',
            description: 'adresses sur les reseaux',
            buttons: [{
                label: "Retour",
                propriety: {
                    isDisabled: false,
                    size: "md",
                    icon: <MdClose />,
                    onClick: () => {
                        setActiveStep(activeStep - 1)
                    }
                }
            },

            {
                label: item?.id ? "Modifier" : "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    size: "md",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSave />,
                }
            }]
        },



    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }
    const champsInit0 = (watch) => [
        [

            {
                name: "mail",
                label: "Login",
                type: "email",
                placeholder: "",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },

        ],


        [
            {
                name: "motpasse",
                label: "Mot de passe",
                type: "password",
                placeholder: "Entrer le mot de passe",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                // prenom: yup.string().required("The status is required"),
                helpText: "8 carateres minimun"


            }
        ],
        [
            {
                name: "motpasse2",
                label: "Confirmation Mot de passe",
                type: "password",
                placeholder: "confirmer le mot de passe",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                // prenom: yup.string().required("The status is required"),
                helpText: ""


            },
        ]

    ]

    const champsInit1 = (watch) => [
        [

            {
                name: "nom",
                label: "Nom",
                type: "text",
                placeholder: "",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },], [
            {
                name: "sigle",
                label: "Sigle",
                type: "text",
                placeholder: "Entrer le sigle",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                // prenom: yup.string().required("The status is required"),
                helpText: ""


            },
        ],


        [
            {
                name: "description",
                label: "Presentation",
                type: "richtextarea",
                placeholder: "Presenter votre organisation",
                requiredText: "false",
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                defaultValue: item?.description

            }
        ]

    ]

    const champsInit2 = (watch) => [

        [
            {
                name: "telephone",
                label: "Telephone",
                type: "text",
                placeholder: "Numero de telephone",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "email",
                label: "Email",
                type: "text",
                placeholder: "Entrer un mail",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
        ],
        [
            {
                name: "pays_id",
                label: "Pays",
                type: "select",
                placeholder: "Selectionner",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                liste: pays?.map(item => {
                    return { key: item?.id, value: item?.nom }

                })

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "province",
                label: "Province",
                type: "text",
                placeholder: "Entrer la province",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
        ],
        [
            {
                name: "code_postal",
                label: "Code postal",
                type: "text",
                placeholder: "Le code postal",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "adresse",
                label: "Adresse",
                type: "text",
                placeholder: "Adresse",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
        ],

    ]
    const champsInit3 = (watch) => [
        [

            {
                name: "logo_id",
                label: "Logo",
                type: "image",
                placeholder: "",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                src: item?.logo

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            }
            ,
            {
                name: "banniere_id",
                label: "Banniere",
                type: "image",
                placeholder: "Presenter votre organisation",
                requiredText: "false",
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                src: item?.banniere


            }
        ]

    ]
    const champsInit4 = (watch) => [

        [
            {
                name: "facebook",
                label: 'Facebook',
                type: "text",
                placeholder: "Numero de telephone",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },

        ],
        [{
            name: "linkdin",
            label: 'linkedin',
            type: "text",
            placeholder: "Entrer un mail",
            requiredText: 'false',
            isDisabledText: 'false',
            displayText: "true",
            helpText: ""

            // validation: yup.string().required("The title is required"),
            // isDisabled: true,
            // size: "lg"


        }],
        [
            {
                name: "twitter",
                label: 'Twitter',
                type: "text",
                placeholder: "Selectionner",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },

        ],
        [
            {
                name: "site_web",
                label: "Site web",
                type: "text",
                placeholder: "Entrer la province",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
        ]
    ]
    const listeForm = [champsInit0, champsInit1, champsInit2, champsInit3, champsInit4]
    useEffect(() => {
        service.getAll(token, "pays", (resp) => {
            setPays(resp?.data?.data)
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
        if (item?.administrateur_id) {
            setData({ administrateur_id: item?.administrateur_id })
            funcs.current.reset(item)
            setActiveStep(1)
        }
    }, [item]);
    useEffect(() => {
        funcs.current.reset(data)
    }, [activeStep]);
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    return (<>
        {activeStep === 0 && <MagicFormNS
            champsInits={listeForm[0]}
            schemaInits={schemaInit0}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit0}
            ref={funcs}
            item={item}
            title="Ajouter votre organisation "
            view='drawer'
            size='xl'
        />}
        {activeStep === 1 && <MagicFormNS
            champsInits={listeForm[1]}
            schemaInits={schemaInit1}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit1}
            ref={funcs}
            item={item}
            title="Ajouter votre organisation "
            view='drawer'
            size='xl'
        />}
        {activeStep === 2 && <MagicFormNS
            champsInits={listeForm[2]}
            schemaInits={schemaInit2}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit1}
            ref={funcs}
            item={item}
            title="Ajouter votre organisation "
            view='drawer'
            size='xl'
        />}
        {activeStep === 3 && <MagicFormNS
            champsInits={listeForm[3]}
            schemaInits={{}}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit1}
            ref={funcs}
            item={item}
            title="Ajouter votre organisation "
            view='drawer'
            size='xl'
        />}
        {activeStep === 4 && <MagicFormNS
            champsInits={listeForm[4]}
            schemaInits={schemaInit3}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Ajouter votre organisation "

            view='drawer'
            size='xl'
        />}
    </>);
}
