import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from '@chakra-ui/react'
import React from 'react'

export const InputForm = React.forwardRef((props, ref) => {
    const {name, label, type,register, placeholder, required, errors, display,helpText, ...rest}=props

    return (
        <>
            <FormControl mt={5} isInvalid={errors[name]} display={display}>
                <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                    {required? `${label}*` :`${label}`  }
                </FormLabel>
                <Input id={name} type={type} {...register(name)} name={name} placeholder={placeholder} {...rest}  focusBorderColor="brand.primary.100" />
               
                <FormErrorMessage>
                    {errors[name]?.message}
                </FormErrorMessage>
                <FormHelperText >{helpText}</FormHelperText>
            </FormControl>
        </>
    )
})
InputForm.displayName = 'InputForm'