import React from 'react';
import {
  ChakraProvider,
  extendTheme,
  theme as baseTheme
} from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme'
import RouteApplication from './Route';
import Header from './Pages/Header';
import { GlobalProvider } from './Pages/Context';
import Footer from './Pages/Footer';


const colors = {
  brand: {
    primary:{
      100:"#3C0753",
    },
    secondary:'#0e1528',
    rouge: {
      900: "#B80000"
    },
    vert: {
      900: '#9C6615',
    },
    bleu:{
      900:'#5F0F40'
    },
    jaune:{
      900:'#FF9800'
    }
  },
  fonts: {
    heading: "Poppins",
    body: "poppins",
  },
}
const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
}

// 3. Extend the theme
// const theme = extendTheme({ breakpoints })
const theme = extendTheme( { colors , breakpoints }, baseTheme, proTheme)
function App() {
  return (
    <ChakraProvider theme={theme}>
      <GlobalProvider >
      
      <RouteApplication />

      
      </GlobalProvider>
    </ChakraProvider>
  );
}

export default App;
