import { Button, Center, Container, Divider, HStack, Stack, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Role from './Role';
import Utilisateur from './Utilisateur';
import { FaTools } from 'react-icons/fa';

const Administration = ({

}) => {
    return (
        <>
            <Container
                pt={{
                    base: '8',
                    lg: '12',
                }}
                pb={{
                    base: '5',
                    lg: '5',
                }}
                minW={{
                    base: '100%',
                    md: '80%',
                }}
            >
                <HStack>
                    <Divider />
                    <Button variant="secondary" leftIcon={<FaTools />} color="white" bg={'brand.primary.100'} fontSize={'lg'}>
                        Administration des utilisateurs
                    </Button>
                    <Divider />
                </HStack>
                <Stack spacing="24" direction={{ base: "row", "md": "column" }} shouldWrapChildren
                    shadow='lg' borderBottomWidth={4} borderBottomColor={"brand.primary.100"}
                >
                    <Tabs size={"lg"}
                        variant="line"

                    >
                        <TabList
                            color={'brand.primary.100'} fontSize={'lg'}
                        >
                            <Tab >Profil</Tab>
                            <Tab>Utilisateur</Tab>
                        </TabList>
                        <TabIndicator />
                        <TabPanels >
                            <TabPanel>
                                <Role

                                />
                            </TabPanel>
                            <TabPanel>
                                <Utilisateur />
                            </TabPanel>

                        </TabPanels>
                        {/* <TabIndicator /> */}
                    </Tabs>


                </Stack>
            </Container>
        </>);
}

export default Administration;


export function Title({
    title,
    ...props
}) {
    return (
        <Center>
            <HStack>
                <Divider />
                <Button variant="secondary" {...props} >
                    {title}
                </Button>
                <Divider />
            </HStack>
        </Center>
    )
}
