import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Container,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    HStack,
    IconButton,
    useBreakpointValue,
    useDisclosure,
    Spacer,
    Heading,
    useColorMode,
    Stack,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Image,
} from '@chakra-ui/react'
import * as React from 'react'
import {
    FiBarChart2,
    FiBookmark,
    FiCheckSquare,
    FiHome,
    FiHelpCircle, FiSearch, FiSettings, FiUser, FiServer
} from 'react-icons/fi'
import LogoSA from './LogoSA'
import { Sidebar } from './Sidebar'
import { ToggleButton } from './ToggleButton'
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from '../Context'
import { useContext, useEffect } from 'react'
import {

    SunIcon,
    MoonIcon,
    ChevronDownIcon
} from '@chakra-ui/icons';
import { NavButton } from './NavButton'
import { FaCpanel, FaFileInvoiceDollar, FaGlobeEurope, FaLockOpen, FaTools, FaUser, FaUserCog, FaUserFriends, FaUsers } from 'react-icons/fa'
import { UserProfile } from './UserProfile'
import Profil from '../Profil'
import { MdBuild } from 'react-icons/md'

const Navbar = () => {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    })
    const { colorMode, toggleColorMode } = useColorMode();
    const navigate=useNavigate()
    const { isOpen: isOpenProfil, onClose: onCloseProfil, onToggle: onToggleProfil } = useDisclosure()
    const { isOpen, onClose, onToggle } = useDisclosure()
    const { login, dataUser, token, logout, retrieve } = useContext(GlobalContext)
    useEffect(() => {
        retrieve()
    }, [])
    return (
        <section>
            <Box as="nav" bg="brand.primary.100" color="white" px={8} fontSize={{base: "sm", md:"md"}} >
                <Flex minWidth='max-content' alignItems='center'
                    // py="3"

                >
                    {/* <Logo /> */}
                    <Link to="/"><LogoSA /></Link>

                    <Spacer />
                    <HStack >
                        {isDesktop && (
                            <ButtonGroup variant="ghost-on-accent" spacing="1" >

                            </ButtonGroup>
                        )}
                    </HStack>
                    <Spacer />
                    {isDesktop ? (
                        <Flex w="100%" justifyContent="space-between" pl="10">
                            <HStack spacing="1">
                                {/* <Link to="/">
                                    <NavButton label="Accueil" icon={FiHome} />
                                </Link>
                                <Link to="/association">
                                    <NavButton label="Organisation de la Société Civile" icon={FaUsers} aria-current="page" />
                                </Link>
                                <Link to="/donateur">
                                    <NavButton label="Bailleurs de fonds" icon={FaGlobeEurope} />
                                </Link> */}

                            </HStack>

                            <HStack>
                                {/* {dataUser?.user?.role_code === "RO-002" && <HStack spacing="1">
                                    <Link to="/organisation">
                                        <NavButton label="Mon organisation" icon={FaUserFriends} aria-current="page" />
                                    </Link>


                                </HStack>} */}
                                {/* {dataUser?.user?.email && <UserProfile boxSize="10" name="DJIMNAIBEYE" mail={dataUser?.user?.email} image="https://tinyurl.com/yhkm2ek8" />} */}
                                <ButtonGroup variant="ghost-on-accent" spacing="1">
                                    {!token &&

                                        <Link to="/registration">
                                            <NavButton label="Registration your app" icon={FiServer} />
                                        </Link>
                                    }

                                    <Button onClick={toggleColorMode}>
                                        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                                    </Button>
                                </ButtonGroup>
                                {token &&
                                    <Menu
                                        display={{ base: 'none', md: 'inline-flex' }}
                                    >
                                        <MenuButton
                                            as={Button}
                                            fontSize={'sm'}
                                            fontWeight={600}
                                            color={'white'}
                                            bgGradient="linear(to-b, brand.primary.100, brand.secondary)"
                                            rightIcon={<ChevronDownIcon />}>
                                            <HStack>
                                                <Avatar
                                                    size='xs'
                                                    name={dataUser ? `${dataUser?.user?.prenom}  ${dataUser?.user?.nom}` : dataUser?.user?.mail}
                                                    src={`${dataUser?.user?.profil?.photo?.url}`}
                                                    mr='12px'
                                                />
                                                <span>{dataUser ? `${dataUser?.user?.prenom}  ${dataUser?.nom}` : dataUser?.user?.mail}</span>
                                            </HStack>
                                        </MenuButton>
                                        <MenuList color={colorMode === 'light' ? 'brand.primary.100' : 'white'}>
                                            <MenuItem
                                                // as={Button}
                                                onClick={() => { onToggleProfil() }} >
                                                <HStack spacing={5}>
                                                    <FaUser />
                                                    <span>
                                                        Mon Profil
                                                    </span>
                                                </HStack>

                                            </MenuItem>
                                            {dataUser?.user?.role_code === "RO-002" &&<MenuItem
                                                 as={Link}
                                                 to={`/organisation/${dataUser?.user?.organisation_id || "neworganisation"}`}
                                                  >
                                                <HStack spacing={5}>
                                                    <FaUserFriends />
                                                    <span>
                                                    Mon organisation
                                                    </span>
                                                </HStack>

                                            </MenuItem>}
                                            {/* {dataUser?.user?.role_code === "RO-002" && <HStack spacing="1">
                                                <Link to="/organisation">
                                                    <NavButton label="Mon organisation" icon={FaUserFriends} aria-current="page" />
                                                </Link>


                                            </HStack>} */}
                                            {dataUser?.user?.role_code === "RO-001" && <MenuItem
                                                as={Link}
                                                to="/administration" >
                                                <HStack spacing={5}>
                                                    <FaUserCog />
                                                    <span>
                                                        Administration
                                                    </span>
                                                </HStack>

                                            </MenuItem>}
                                            {dataUser?.user?.role_code === "RO-001" &&
                                                <MenuItem
                                                    as={Link}

                                                    to="/parametrage"
                                                >
                                                    <HStack spacing={5}>
                                                        <FaTools />
                                                        <span>
                                                            Parametrage
                                                        </span>
                                                    </HStack>

                                                </MenuItem>}
                                            {/* {dataUser?.user?.role_code === "RO-001" && <HStack spacing="1">
                                                <Link to="/administration">
                                                    <NavButton label="Administration" icon={FaUserCog} aria-current="page" />
                                                </Link>
                                                <Link to="/parametrage">
                                                    <NavButton label="Parametrage" icon={FaTools} />
                                                </Link>

                                            </HStack>} */}
                                            {/* <MenuItem
                                            as={Button}
                                            onClick={() => { onToggleProfil() }} >
                                            <HStack spacing={5}>
                                                <MdBuild />
                                                <span>
                                                    Mon organisation
                                                </span>

                                            </HStack>

                                        </MenuItem> */}
                                            <MenuItem

                                                onClick={() => { logout(); navigate('/'); window.location.reload() }} >
                                                <HStack spacing={5}>
                                                    <FaLockOpen />
                                                    <span>
                                                        Deconnexion
                                                    </span>

                                                </HStack>
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                }

                            </HStack>
                        </Flex>
                    ) : (
                        <>
                            <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
                            <Drawer
                                isOpen={isOpen}
                                placement="left"
                                onClose={onClose}
                                isFullHeight
                                preserveScrollBarGap
                                // Only disabled for showcase
                                size="full"
                                trapFocus={false}
                            >
                                <DrawerOverlay />
                                <DrawerContent>
                                    <Sidebar />
                                </DrawerContent>
                            </Drawer>
                        </>
                    )}
                </Flex>
                {isOpenProfil && <Profil
                    isOpen={isOpenProfil}
                    onClose={onCloseProfil}
                    onToggle={onToggleProfil}
                    utilisateur_id={dataUser?.user?.id}

                />}
            </Box>

        </section>
    )
}
export default Navbar