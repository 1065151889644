import { FormControl, FormErrorMessage, FormHelperText, FormLabel, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import React from 'react'

export const NumberForm = React.forwardRef((props, ref) => {
    const { name, label, helpText, liste, defaultValue, placeholder, required, errors, control, Controller, ...rest } = props

    return (
        <>
            <FormControl mt={5} isInvalid={errors[name]} _disabled={true}>
                <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                    {required ? `${label}*` : `${label}`}
                </FormLabel>

                <Controller
                    control={control}
                    name={name}
                    defaultValue={0 || defaultValue }
                    render={({ field: { onChange, value, ref } }) => (
                        <NumberInput
                            onChange={onChange}
                            value={value}
                            {...rest}
                            focusBorderColor="brand.primary.100"

                        >
                            <NumberInputField />
                            <NumberInputStepper >
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    )}
                />
             
                <FormErrorMessage>
                    {errors[name]?.message}
                </FormErrorMessage>
                <FormHelperText >{helpText}</FormHelperText>
            </FormControl>
        </>
    )
})
NumberForm.displayName = 'NumberForm'