import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  HStack,
  Stack,
  Link,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  useColorModeValue,
  CheckboxGroup,
  Checkbox,
  Heading
} from '@chakra-ui/react'
import * as React from 'react'
import { BiSearch } from 'react-icons/bi';
import { FiSearch, FiSend } from 'react-icons/fi';
import { ListeAssociation } from '../association/liste';
//   import { Footer } from '../footer';
//   import { Navbar } from '../home/Navbar';
import { BannerDonateur } from './banner';
import { ListeADonateur } from './liste';
import { Title } from '../Administration';
import { CheckboxCard, CheckboxCardGroup } from '../Composants/CheckboxCardGroup';
import { GlobalContext } from '../Context';
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';

export const Donateur = () => {
  const [state, setState] = React.useState({});
  const [selectedSecteurs, setSelectedSecteurs] = React.useState([]);
  const [selectedPays, setSelectedPays] = React.useState([]);
  const [selectedDonateurs, setSelectedDonateurs] = React.useState([]);
  const [financements, setFinancements] = React.useState([]);
  const { service, token, logout } = React.useContext(GlobalContext)
  const navigate = useNavigate()
  React.useEffect(() => {
    service.getAll(token, "search", (resp) => {
      console.log("resp?.data?.data", resp?.data)
      setState(resp?.data)

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }
        console.error(err?.response?.status === 401)
      }
    )


    service.create(token, "filterfinancement", {
      secteurs: selectedSecteurs,
      donateurs: selectedDonateurs,
      pays: selectedPays

    }, (resp) => {
      console.log("resp?.data?.data", resp?.data)
      setFinancements(resp?.data)

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }
        console.error(err?.response?.status === 401)
      }
    )
  }, []);
  React.useEffect(() => {



  }, [state]);
  const handleSearch = () => {
    service.create(token, "filterfinancement", {
      secteurs: selectedSecteurs,
      donateurs: selectedDonateurs,
      pays: selectedPays

    }, (resp) => {
      console.log("resp?.data?.data", resp?.data)
      setFinancements(resp?.data)

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }
        console.error(err?.response?.status === 401)
      }
    )
  }
  return (
    <Box as="section" height="full" overflowY="auto">
      {/* <Box display={{ base: "none", sm:"block"}}>
      <BannerDonateur />
      </Box> */}
      <Container
        pt={{
          base: '8',
          lg: '12',
        }}
        pb={{
          base: '12',
          lg: '24',
        }}
        minW={{
          base: '100%',
          md: '80%',
        }}
      >
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          justify="space-between"
        >
          <Box>
            <Link to="/association">
              <Button
                size={{ base: "sm", md: "md" }}
                w="full"
                leftIcon={<FiSend />}
                textTransform="uppercase"
                bg="jaune.900" color={"white"}>J'inscris mon organisation</Button>
            </Link>
          </Box>

          <Box>
            <Link to="/donateur">
              <Button
                size={{ base: "sm", md: "md" }}
                w="full"
                leftIcon={<FiSend />}
                textTransform="uppercase"
                bg="bleu.900" color={"white"}>Je soumets une opportunité de financement</Button>
            </Link>
          </Box>
        </Stack>
        <Box

          px={{
            base: '4',
            md: '6',
          }}
          my="2"
          py="5"
          boxShadow={useColorModeValue('sm', 'sm-dark')}
          borderTopWidth="6px"
          // borderBottomWidth="2px"
          borderColor="brand.primary.100"
        >
          <Stack
            spacing="4"
            direction="column"
            justify="space-between"
          >
            <Box>
              {/* <Title
                title="Opportunités de financement"
                color="white" bg={'brand.primary.100'} fontSize={'lg'}
              /> */}
              <Stack
                spacing="4"
                direction="row"
                justify="space-between"
              >

                <Box>
                  {/* <Button
                    size={{ base: "sm", md: "md" }}
                    w="full"
                    leftIcon={<FiSend />}
                    textTransform="uppercase"
                    bg="bleu.900" color={"white"}>Je soumets une opportunité de financement
                    </Button> */}
                </Box>
              </Stack>
            </Box>
            <Box>
              <Stack
                justify="space-between"
                direction={{
                  base: 'column',
                  md: 'row',
                }} spacing="6">
                <Box></Box>
                <Box>
                  <FormControl size={{ base: "sm", md: "md" }}>
                    <FormLabel><Heading fontSize={{ base: "sm", md: "md" }} size='sm' textTransform="uppercase"> Bailleurs de fonds</Heading></FormLabel>
                    <CheckboxCardGroup defaultValue={[]} spacing="3" onChange={setSelectedDonateurs}>

                      {state?.donateurs?.map(item => {
                        return <CheckboxCard key={item?.id} value={item?.id} >
                          <Text color='brand.primary.100' fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                            {item?.nom}
                          </Text>
                        </CheckboxCard>
                      })}

                    </CheckboxCardGroup>
                    {/* <CheckboxGroup defaultValue={['AFD']}>
                    <Stack spacing={[1, 5]} direction="column">
                      <Checkbox value='AFD'>AFD</Checkbox>
                      <Checkbox value='Banque Mondiale'>Banque Mondiale</Checkbox>
                      <Checkbox value='Union Européenne'>Union Européenne</Checkbox>
                      <Checkbox value='Banque Africaine de Développement'>Banque Africaine de Développement</Checkbox>
                      <Checkbox value='Programme des Nations Unies pour le Développement'>Programme des Nations Unies pour le Développement</Checkbox>
                    </Stack>
                  </CheckboxGroup> */}
                  </FormControl>
                </Box>
                {/* <Box>
                  <FormControl size={{ base: "sm", md: "md" }}>
                    <FormLabel><Heading fontSize={{ base: "sm", md: "md" }} size='sm' textTransform="uppercase"> Pays</Heading></FormLabel>

                    <CheckboxCardGroup defaultValue={[]} spacing="3" onChange={setSelectedPays}>

                      {state?.pays?.map(item => {
                        return <CheckboxCard key={item?.id} value={item?.id}>
                          <HStack>
                            <ReactCountryFlag countryCode={item?.code} />
                            <Text color='brand.primary.100' fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                              {item?.nom}
                            </Text>
                          </HStack>

                        </CheckboxCard>
                      })}

                    </CheckboxCardGroup>
                  </FormControl>
                </Box> */}
                <Box>
                  <FormControl size={{ base: "sm", md: "md" }} >
                    <FormLabel><Heading fontSize={{ base: "sm", md: "md" }} size='sm' textTransform="uppercase">	Secteurs d'intervention</Heading></FormLabel>
                    {/* <CheckboxGroup bg="bleu.900" defaultValue={['Agriculture']}> */}
                      <CheckboxCardGroup defaultValue={[]} spacing="3" onChange={setSelectedSecteurs} >

                        {state?.secteurs?.map(item => {
                          return <CheckboxCard key={item?.id} value={item?.id}>
                            <Text color='brand.primary.100' fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                              {item?.nom}
                            </Text>
                          </CheckboxCard>
                        })}

                      </CheckboxCardGroup>
                    {/* </CheckboxGroup> */}
                  </FormControl>
                </Box>
                <Box></Box>
              </Stack>
            </Box>
            <Box>
              <Stack
                spacing="4"
                direction="row"
                justify="space-between"
              >
                <Box>

                </Box>
                <Box>
                  <Button
                    leftIcon={<FiSearch />}
                    color="white"
                    size={{ base: "sm", md: "md" }}
                    w={{ base: "full", md: "lg" }}
                    bg="brand.primary.100"
                    onClick={handleSearch}
                  >
                    Chercher
                  </Button>
                </Box>
                <Box>

                </Box>
              </Stack>
            </Box>
          </Stack>

        </Box>
      </Container>
      {
        state?.donateurs?.map(donateur => {
          const filterFinancement = financements.filter(item => item?.donateur_id === donateur?.id)
          return <> {filterFinancement.length ? <ListeADonateur title={donateur?.nom} financements={filterFinancement} /> : ""}</>
        })
      }


    </Box>
  )
}