import { encode, decode } from 'js-base64';
const ellipticcurve =  require("../starkbank-ecdsa");
const sha256 = require("js-sha256");
const BigInt = require("big-integer");
const PrivateKey = ellipticcurve.PrivateKey
const PublicKey = ellipticcurve.PublicKey
const math = ellipticcurve.math
const binary = ellipticcurve.binary
const integer = ellipticcurve.integer

export const generateKey=(password, hashfunc = null)=>{
    if (hashfunc == null) {
        hashfunc = sha256;
    }
    const hashMessage = hashfunc(encode(password));
    const numberMessage = binary.numberFromHex(hashMessage);
    return numberMessage.toString()
}
export const encrypt = (text, key) => {
    let s = '';
    for (let i = 0; i < text.length; i++) {
      // append the result of the char from the code-point that results from
      // XORing the char codes (or 0 if one string is too short)
      s += String.fromCharCode(
        (text.charCodeAt(i) ) ^ (key.charCodeAt(i%key.length))
      );
    }
  
    return encode(s);
  };
  export const decrypt = (text, key) => {
    text=decode(text)
    let s = '';
    for (let i = 0; i < text.length; i++) {
      // append the result of the char from the code-point that results from
      // XORing the char codes (or 0 if one string is too short)
      s += String.fromCharCode(
        (text.charCodeAt(i) ) ^ (key.charCodeAt(i%key.length))
      );
    }
  
    return s;
  };
export const registration = (identifiant) => {
    let privateKeyUser = new PrivateKey()
    let publicKeyUser = privateKeyUser.publicKey()
    identifiant['public_key'] = publicKeyUser.toPem()
    return { identifiant, secret: privateKeyUser }

}


export const validation = (response,code,privateKey, hashfunc = null) => {
    if (hashfunc == null) {
        hashfunc = sha256;
    }
    const {public_key_cert, public_key_ip, secret}=response
    const respSecret= PrivateKey.fromPem(decode(secret))
    const publicKeyIP=PublicKey.fromPem(decode(public_key_ip))
    const identityCert=public_key_cert
    const  curve = publicKeyIP.curve;
    const codeNumberInv=math.inv(BigInt(code), curve.N)
    const secretCRT = integer.modulo((privateKey.secret.add(codeNumberInv.multiply(respSecret.secret))), curve.N)
    const privateKeyCRT=new PrivateKey(curve, secretCRT )
    return {data: { ...identityCert, public_key : privateKeyCRT.publicKey().toPem()}, secret:secretCRT}

}

// export const preAuthentication = (verifier, prover, privateKeyProver, hashfunc = null) => {
//     if (hashfunc == null) {
//         hashfunc = sha256;
//     }
//     const verifierObject = JSON.parse(verifier)
//     const publicKeyCertVerifier = PublicKey.fromPem(verifierObject.publicKeyCert)
//     const curve = publicKeyCertVerifier.curve
//     const privateKeyEP = new PrivateKey()
//     let hashMessage = hashfunc(verifier);
//     let numberMessage = integer.modulo(binary.numberFromHex(hashMessage), curve.N);
//     let G = math.multiply((math.add(publicKeyCertVerifier.point, math.multiply(publicKeyIP.point, numberMessage, curve.N, curve.A, curve.P), curve.A, curve.P)), privateKeyEP.secret, curve.N, curve.A, curve.P);
//     const GPem = new PublicKey(G, curve).toPem()
//     let proverObject = JSON.parse(prover)
//     proverObject['publicKeyEP'] = privateKeyEP.publicKey().toPem()
//     const hashCommit = hashfunc(JSON.stringify(proverObject))
//     const numberHashCommit = binary.numberFromHex(hashCommit);
//     const K = integer.modulo(privateKeyProver.secret.add(privateKeyEP.secret.multiply(numberHashCommit)), curve.N)

//     return {
//         privateKeyEP: privateKeyEP.toPem(),
//         G: GPem,
//         K: K.toString()
//     }

// }
// export const verify = (prover, privateKeyVerifier, publicKeyEPProver, G, K, hashfunc = null) => {
//     if (hashfunc == null) {
//         hashfunc = sha256;
//     }
//     let proverObject = JSON.parse(prover)
//     GPrime = math.multiply(publicKeyEPProver.point, privateKeyVerifier.secret, curve.N, curve.A, curve.P)
//     const GPrimePem = new PublicKey(GPrime, curve).toPem()
//     KP = math.multiply(curve.G, BigInt(K), curve.N, curve.A, curve.P)
//     const KPPert = new PublicKey(KP, curve).toPem()
//     proverObject['publicKeyEP'] = publicKeyEPProver.toPem()
//     const hashCommit = hashfunc(JSON.stringify(proverObject))
//     const numberHashCommit = integer.modulo(binary.numberFromHex(hashCommit), curve.N);
//     const HX = math.multiply(publicKeyEPProver.point, numberHashCommit, curve.N, curve.A, curve.P)
//     const proverHash = hashfunc(prover)
//     const numberProverHash = integer.modulo(binary.numberFromHex(proverHash), curve.N);
//     const KPPrime = math.add(math.add(PublicKey.fromPem(proverObject.publicKeyCert).point, math.multiply(publicKeyIP.point, numberProverHash, curve.N, curve.A, curve.P), curve.A, curve.P), HX, curve.A, curve.P)
//     const KPPrimePert = new PublicKey(KPPrime, curve).toPem()
//     return KPPert === KPPrimePert

// }

