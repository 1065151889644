import React, { useState, useEffect, useContext, useRef } from 'react';

import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Button, useDisclosure, useSteps, useToast } from '@chakra-ui/react';
import MagicFormNS from '../Task/MagicFormNS';

const Profil = ({
    isOpen,
    onToggle,
    onClose,
    utilisateur_id

}) => {
    const { service, dataUser, token } = useContext(GlobalContext)
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    const toast = useToast()
    const [profil, setProfil] = useState({});

    const onSubmit = async (data) => {

        console.log("utilisateur_id", utilisateur_id)
        const {photo_id_full, photo, url, id, ...rest } = data

        {!id? service.create(token, "profil", {...rest, utilisateur_id: utilisateur_id },
            (resp) => {
                toast({
                    title: 'Succes.',
                    description: "Le compte mail a ete cree avec succes",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

            }, err => {
                if (err.response?.status === 405) {
                    toast({
                        title: 'Probleme est survenu.',
                        description: "Le compte mail est deja utilise",
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }

            }):
            service.update(token, "profil",id, {...rest, utilisateur_id: utilisateur_id },
            (resp) => {
                toast({
                    title: 'Succes.',
                    description: "Le compte mail a ete cree avec succes",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

            }, err => {
                if (err.response?.status === 405) {
                    toast({
                        title: 'Probleme est survenu.',
                        description: "Le compte mail est deja utilise",
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }

            })
        }




    }
    const onGet = () => {
        service.get(token, "profil",  utilisateur_id ,
            (resp) => {
                setProfil(resp?.data)
            }, err => {
                // if (err.response?.status === 405) {
                //     toast({
                //         title: 'Probleme est survenu.',
                //         description: "Le compte mail est deja utilise",
                //         status: 'warning',
                //         duration: 9000,
                //         isClosable: true,
                //     })
                // }

            })

    }
    const schemaInit = {
        nom: yup.string().required("Champ nom est obligatoire"),
        utilisateur_id: yup.string().required("Champ utilisateur est obligatoire"),
        telephone: yup.string().required("Champ telephone est obligatoire"),

    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    size: "lg",
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    size: "xl",
                    px: '10'
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }
    const [item, setItem] = useState({
        utilisateur_id: utilisateur_id,
    });

    const champsInit = (watch) => [
        [
            {
                name: "photo_id",
                label: "Photo de profil",
                type: "image",
                placeholder: "Entrer le nom",

                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: "",
                src:profil?.photo


                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
        ],

        [
            
            {
                name: "nom",
                label: "Nom",
                type: "text",
                placeholder: "Entrer le nom",

                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "prenom",
                label: "prenom",
                type: "text",
                placeholder: "Entrer le prenom",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                // prenom: yup.string().required("The status is required"),
                helpText: ""


            },
        ],
        [
            {
                name: "telephone",
                label: "Telephone",
                type: "text",
                placeholder: "Entrer le nom",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "telephone2",
                label: "Telephone 2",
                type: "text",
                placeholder: "Entrer le deuxieme numero de telephone",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                // prenom: yup.string().required("The status is required"),
                helpText: ""


            },
        ],

    ]
    useEffect(() => {
        funcs?.current?.reset(profil)
    }, [profil]);
    useEffect(() => {
        onGet()
    }, [utilisateur_id]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Profil de l'utilisateur"
            view='drawer'
            size='xl'
        />
    </>);
}

export default Profil;