import { Avatar, Box, VStack, Text } from '@chakra-ui/react'
import * as React from 'react'

export const UserProfile = (props) => {
  const { name, image, email } = props
  return (
    <VStack spacing="3" ps="2">
      <Avatar name={email} src={image} boxSize="10" />
      <Box>
        <Text color="blue" fontWeight="medium" fontSize="xs">
          {email}
        </Text>
        <Text color="white" fontSize="xs">
          {email}
        </Text>
      </Box>
    </VStack>
  )
}
