import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
const Secteur = ({

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const navigate=useNavigate()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const [secteurs, setSecteurs] = useState([]);
    const [item, setItem] = useState({});
    const onGet = () => {
        service.getAll(token, "secteur", (resp) => {
            setSecteurs(resp?.data?.data)
            // alert(JSON.stringify(resp?.data?.data))

        },
            err => {
                if(err?.response?.status===401){
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status===401)
            }
        )
    }
    const columns = [
        {
            title: 'Nom',
            field: 'nom',
            // render: rowData => <><ReactCountryFlag countryCode={rowData?.code}/> {rowData?.nom}</>
        },
        {
            title: 'Code',
            field: 'code',
        }
    ]
    useEffect(() => {

    }, [secteurs]);
    useEffect(() => {
        onGet()
    }, []);
    return (
        <Box w="full" >
            <Datatable
                icon={<BsListCheck />}
                title={"Liste de secteurs d'activites"}
                data={secteurs}
                onGet={onGet}
                columns={columns}
                setData={setSecteurs}
                setItem={setItem}
                onToggle={onToggle}
                canAdd={true}
                canEdit={true}
            />
            {/* <SimpleTableNS
                title="Liste des pays membres"
                data={{
                    header: ["Code", "Nom", "Action"],
                    body: pays
                }}
                maxW="600px"
                onOpen={onOpen}
            /> */}
            <FormSecteur
                isOpen={isOpen}
                onClose={onClose}
                onGet={onGet}
                item={item}


            />

        </Box>);
}

export default Secteur;

const FormSecteur = ({
    isOpen,
    onToggle,
    onClose,
    onGet,
    item

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    let navigate = useNavigate();
    const { service, token, logout } = useContext(GlobalContext)
    const onSubmit = async (data) => {


        const {id, tableData,url,photo, ...rest}=data

        !id? service.create(token, "secteur", rest, (resp) => {
            onGet()
        },
            err => {
                if(err?.response?.status===401){
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status===401)
            }
        ):
        service.update(token, "secteur",id, rest, (resp) => {
            onGet()
        },
            err => {
                if(err?.response?.status===401){
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status===401)
            }
        )






    }
    const schemaInit = {
        nom: yup.string().required("Champ nom est obligatoire"),
        code: yup.string().required("Champ code est obligatoire").uppercase(),
    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    size: "md",
                    icon:<MdClose/>,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label:"Supprimer",
                propriety: {
                    isDisabled: false,
                    display: !item?.id && 'none',
                    size: "md",
                    px: '10',
                    icon:<MdDelete/>,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label:item?.id?"Modifier": "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    size: "md",
                    px: '10',
                    icon:item?.id?<MdEdit/>: <MdSave/>,
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }


    const champsInit = (watch) => [

        [

            {
                name: "nom",
                label: "Nom du secteur d'activite",
                type: "text",
                placeholder: "Entrer le nom du secteur d'activite",

                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "code",
                label: "Code",
                type: "text",
                placeholder: "Entrer le code du secteur d'activite",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""


            },
        ]
    ]
    useEffect(() => {
        console.log("item", item)
        funcs.current.reset(item)
    }, [item]);
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Formulaire "
            size='4xl'
        />
    </>);
}
